import axios from 'axios'

export const verifyEmail = async (payload:{userId:number, code:string}) =>{
  try {
    const res = await axios.post(`/v1/users/verify-email`, payload);
    return res.data.data
  } catch (e: any) {
    throw e.response?.data?.message || 'Something went wrong';
  }
};

export const sendVerificationEmail = async (payload:{email:string}) =>{
  try {
    const res = await axios.post(`/v1//users/send-email-verification-code`, payload);
    return res.data.data
  } catch (e: any) {
    throw e.response?.data?.message || 'Something went wrong';
  }
};