import {
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DeviceContext } from '../../../../deviceType';
import classes from './styles.module.scss';
import { menuController } from '@ionic/core';
import StoreMenu from '../../../storeMenu/storeMenu';
import Menu from '../../../storeMenu/menu';
import { User } from '../../../../types/user';
import { hideMenuPaths } from '../../../../utils';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';

const SellerNav = (props: { userDetails: User }) => {
  const history = useHistory();
  const size = useContext(DeviceContext);
  const path = useLocation();
  const [open, setOpen] = useState<any>();
  const [openStoreMenu, setOpenStoreMenu] = useState<{
    isOpen: boolean;
    event: Event | null;
  }>({
    isOpen: false,
    event: null,
  });

  useEffect(() => {
    const closeMenu = async () => {
      if (open && hideMenuPaths.includes(path.pathname)) {
        await menuController.close('menu');
      }
    };

    closeMenu();
  }, [path.pathname, open]);
  
  const openCntx = (e: any) => {
    setOpenStoreMenu({ isOpen: !openStoreMenu.isOpen, event: e });
  };
  return (
    <IonHeader className={classes.Container + " !bg-white !border-0 !shadow-none"}>
      <Menu
        open={openStoreMenu.isOpen}
        close={openCntx}
        userDetails={props.userDetails}
        openEvent={openStoreMenu.event!}
      />
      {size.width >= 769 ? (
        <IonToolbar color="light" className={classes.holder+ " !bg-white !border-0 !shadow-none"} mode="md">
          <IonGrid className=" !bg-white !border-0 !shadow-none">
            <IonRow>
              <IonCol size={'2'} className={classes.logo}>
                <div
                  className={classes.holder}
                  onClick={() => {
                    history.push('/dashboard/home');
                  }}
                >
                  <IonIcon src={logo} style={{ width: '2.5rem' }}></IonIcon>
                  <span
                    className={classes.span1}
                    style={{
                      color: 'var(--ion-color-primary)',
                    }}
                  >
                    Bazaar
                  </span>
                  <span className={classes.span2}>Cheap</span>
                </div>
              </IonCol>
              <IonCol size="1.3" className={classes.storeMenu}>
                <div className={classes.chip}>
                  <StoreMenu
                    userDetails={props.userDetails}
                    open={openStoreMenu.isOpen}
                    openCntx={openCntx}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      ) : (
        <IonToolbar color="light" className={classes.holder + " !bg-white !border-0 !shadow-none"} mode="md">
          <IonGrid className=" !bg-white !border-0 !shadow-none">
            <IonRow>
              <IonCol
                size="8"
                className={classes.logo}
                onClick={() => {
                  history.push('');
                }}
              >
                <div
                  className={classes.holder}
                  onClick={() => {
                    history.push('/dashboard/home');
                  }}
                >
                  <IonIcon src={logo} style={{ width: '3rem' }}></IonIcon>
                  <span
                    className={classes.span1}
                    style={{
                      color: 'var(--ion-color-primary)',
                    }}
                  >
                    Bazaar
                  </span>{' '}
                  <span className={classes.span2}>Cheap</span>
                </div>
              </IonCol>
              <IonCol size="2" className={classes['store-menu-col']}>
                <div className={classes.chip}>
                  <StoreMenu
                    userDetails={props.userDetails}
                    open={openStoreMenu.isOpen}
                    openCntx={openCntx}
                  />
                </div>
              </IonCol>
              <IonCol size="2" className={classes['menu-col']}>
                <IonButtons>
                  <IonMenuButton
                    onClick={async() => {
                      await menuController.open('menu');
                    }}
                  >
                  </IonMenuButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      )}
    </IonHeader>
  );
};

export default SellerNav;
