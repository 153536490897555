import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import { Link, useLocation } from 'react-router-dom';
import WishlistIcon from '../wish-list/wish-list-icon';
import ImageCarousel from '../../../components/ImageCarousel';
import { ProductType } from '../../../types/product';
import {
  caretDown,
  caretUp,
  shareSocialSharp,
} from 'ionicons/icons';
import classNames from 'classnames';

const ProductCard = (props: {
  data: ProductType;
  disableWishlist: boolean;
  isVendor?: boolean;
}) => {
  const size = useContext(DeviceContext);
  const location = useLocation();
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const [locations, setLocations] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [storeOpen, setStoreOpen] = useState<boolean>(true);

  useEffect(() => {
    setItem(props.data);
  }, [props.data]);

  useEffect(() => {
    if (item?.store) {
      const locs = item?.store.location!.split(',');
      const locations = locs?.slice(0, 2).join(',');
      setLocations(locations!);
    }
    if (item?.price) {
      const itemPrice = item.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setPrice(itemPrice);
    }
  }, [item]);
  const shareAction = () => {
    const url = window.location.href;
    window.navigator.clipboard.writeText(url);
  };

  const statusBadge = classNames(
    'px-3 py-[2px] text-xs rounded-md capitalize font-semibold',
    {
      'bg-orange-200 text-orange-500': props.data.status === 'pending',
      'bg-gray-200 text-gray-500': props.data.status === 'draft',
      'bg-red-200 text-red-500': props.data.status === 'rejected',
      'bg-green-200 text-green-500': props.data.status === 'published',
    }
  );
  return (
    <IonCard
      className={
        classes.productCard +
        ' w-full h-auto m-0 relative overflow-hidden border-none rounded-none bg-transparent'
      }
    >
      {size.width > 768 && (
        <IonGrid className={classes.desktopGrid + ' size-full p-0 m-0'}>
          <IonRow className={'w-full h-[75dvh] '}>
            <IonCol size="5" className={'size-full p-0 m-0'}>
              <IonRow className={'size-full '}>
                <IonCol size={'12'} className="size-full p-0">
                  <ImageCarousel
                    data={props.data.images}
                    name={props.data.name}
                  />
                  <div
                    className={
                      'cursor-pointer absolute !aspect-square w-[2.5rem] h-auto top-2 right-2 bg-white rounded-full flex items-center justify-center p-2'
                    }
                    onClick={shareAction}
                  >
                    <IonIcon
                      icon={shareSocialSharp}
                      color="primary"
                      size="default"
                    ></IonIcon>
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol
              size="7"
              className={'size-full py-4 m-0 overflow-y-scroll '}
            >
              {' '}
              <IonRow
                className={
                  classes.info +
                  ' w-full h-auto pt-4 px-4 max-h-[14vh] overflow-hidden items-center'
                }
              >
                <IonCol size={'10.5'} className="h-full">
                  <div className={classes.title}>{item?.name}</div>
                </IonCol>
                {!props.disableWishlist && (
                  <IonCol
                    size="1.5"
                    className="flex items-start justify-center"
                  >
                    <div
                      className={
                        'ion-activatable ripple-parent circle size-fit'
                      }
                    >
                      <IonRippleEffect color="secodary"></IonRippleEffect>
                      <WishlistIcon productId={props.data.id} />
                    </div>
                  </IonCol>
                )}
              </IonRow>
              <IonRow className={classes.price + ' w-full h-fit py-2 px-4'}>
                <IonCol size={'12'}>UGX {price}</IonCol>
              </IonRow>
              {props.isVendor && <div className="flex w-full px-4">
                <span className={statusBadge}>{props.data.status}</span>
              </div>}
              <IonRow
                className={classes.productDetails + ' w-full h-auto pb-2 px-4'}
              >
                <IonCol>
                  <IonRow
                    className={
                      classes.head +
                      ' w-full h-auto border-b-[1px] border-gray-400 cursor-pointer py-2 mb-2'
                    }
                    onClick={() => {
                      setDetailsOpen(!detailsOpen);
                    }}
                  >
                    <IonCol size="10" className={classes.label}>
                      Product Details
                    </IonCol>
                    <IonCol
                      size="2"
                      className="w-auto h-fit flex items-center justify-center"
                    >
                      {detailsOpen && (
                        <IonIcon
                          icon={caretUp}
                          mode="ios"
                          color="primary"
                        ></IonIcon>
                      )}
                      {!detailsOpen && (
                        <IonIcon
                          icon={caretDown}
                          mode="ios"
                          color="primary"
                        ></IonIcon>
                      )}
                    </IonCol>
                  </IonRow>
                  {detailsOpen && (
                    <div
                      className={classes.content}
                      dangerouslySetInnerHTML={{ __html: item?.details! }}
                    ></div>
                  )}
                </IonCol>
              </IonRow>
              <IonRow
                className={classes.storeDetails + ' w-full h-auto pb-2 px-4'}
              >
                <IonCol>
                  <IonRow
                    className={
                      classes.head +
                      ' w-full h-auto border-b-[1px] border-gray-400 cursor-pointer py-2 mb-2'
                    }
                    onClick={() => {
                      setStoreOpen(!storeOpen);
                    }}
                  >
                    <IonCol size="10" className={classes.label}>
                      Shop Details
                    </IonCol>
                    <IonCol
                      size="2"
                      className="w-auto h-fit flex items-center justify-center"
                    >
                      {storeOpen && (
                        <IonIcon
                          icon={caretUp}
                          mode="ios"
                          color="primary"
                        ></IonIcon>
                      )}
                      {!storeOpen && (
                        <IonIcon
                          icon={caretDown}
                          mode="ios"
                          color="primary"
                        ></IonIcon>
                      )}
                    </IonCol>
                  </IonRow>
                  {storeOpen && (
                    <div
                      className={
                        classes.content + ' w-full h-auto flex px-4 flex-col'
                      }
                    >
                      <Link
                        className="w-full h-auto my-0 py-2"
                        to={{
                          pathname: `/${item?.store?.slug}`,
                          state: { id: item?.store?.id },
                        }}
                      >
                        {item?.store?.name}
                      </Link>
                      <div
                        className={
                          classes.locations + ' w-full h-auto flex flex-col '
                        }
                      >
                        <div className={classes.label + ' w-full h-auto'}>
                          Locations
                        </div>
                        <div
                          className={
                            classes.list +
                            ' w-full h-auto mb-1 pl-2 flex flex-col items-start'
                          }
                        >
                          {item.store?.location!.split(',').map((s, idx) => {
                            return (
                              <div
                                key={idx}
                                className={classes.item + ' w-full h-auto py-2'}
                              >
                                {s}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className={
                          classes.contacts + ' w-full h-auto flex flex-col '
                        }
                      >
                        <div className={classes.label + ' w-full h-auto'}>
                          Contacts
                        </div>
                        <div
                          className={
                            classes.list +
                            ' w-full h-auto mb-1 pl-2 flex flex-col items-start'
                          }
                        >
                          {item.store?.phoneNumber!.split(',').map((s, idx) => {
                            return (
                              <div
                                key={idx}
                                className={classes.item + ' w-full h-auto py-2'}
                              >
                                {s}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {size.width <= 768 && (
        <IonGrid className={classes.grid + ' w-full h-auto p-0 '}>
          <IonRow className={'w-full h-auto aspect-[2/2.5] '}>
            <IonCol size={'12'} className="size-auto p-0">
              <ImageCarousel data={props.data.images} name={props.data.name} />
              {!props.disableWishlist && (
                <div
                  className={
                    'cursor-pointer absolute !aspect-square w-[2.5rem] h-auto top-2 right-2 bg-white rounded-full flex items-center justify-center p-2'
                  }
                  onClick={shareAction}
                >
                  <IonIcon
                    icon={shareSocialSharp}
                    color="primary"
                    size="default"
                  ></IonIcon>
                </div>
              )}
            </IonCol>
          </IonRow>
          <IonRow
            className={
              classes.info +
              ' w-full h-auto pt-4 px-4 max-h-[14vh] overflow-hidden'
            }
          >
            <IonCol size={'10.5'} className="h-full">
              <div className={classes.title}>{item?.name}</div>
            </IonCol>
            <IonCol size="1.5" className="flex items-start justify-center">
              <div className={'ion-activatable ripple-parent circle size-full'}>
                <IonRippleEffect color="secodary"></IonRippleEffect>
                <WishlistIcon productId={props.data.id} />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className='text-secondary w-full h-auto pb-2 px-4 text-lg font-medium'>
            <IonCol size={'12'}>UGX {price}</IonCol>
          </IonRow>
          {props.isVendor && <div className="flex w-full px-4">
            <span className={statusBadge}>{props.data.status}</span>
          </div>}
          <IonRow
            className={classes.productDetails + ' w-full h-auto pb-2 px-4'}
          >
            <IonCol>
              <IonRow
                className={
                  classes.head +
                  ' w-full h-auto border-b-[1px] border-gray-400 cursor-pointer py-2 mb-2'
                }
                onClick={() => {
                  setDetailsOpen(!detailsOpen);
                }}
              >
                <IonCol size="10" className={classes.label}>
                  Product Details
                </IonCol>
                <IonCol
                  size="2"
                  className="w-auto h-fit flex items-center justify-center"
                >
                  {detailsOpen && (
                    <IonIcon
                      icon={caretUp}
                      mode="ios"
                      color="primary"
                    ></IonIcon>
                  )}
                  {!detailsOpen && (
                    <IonIcon
                      icon={caretDown}
                      mode="ios"
                      color="primary"
                    ></IonIcon>
                  )}
                </IonCol>
              </IonRow>
              {detailsOpen && (
                <div
                  className={classes.content}
                  dangerouslySetInnerHTML={{ __html: item?.details! }}
                ></div>
              )}
            </IonCol>
          </IonRow>
          <IonRow className={classes.storeDetails + ' w-full h-auto pb-2 px-4'}>
            <IonCol>
              <IonRow
                className={
                  classes.head +
                  ' w-full h-auto border-b-[1px] border-gray-400 cursor-pointer py-2 mb-2'
                }
                onClick={() => {
                  setStoreOpen(!storeOpen);
                }}
              >
                <IonCol size="10" className={classes.label}>
                  Shop Details
                </IonCol>
                <IonCol
                  size="2"
                  className="w-auto h-fit flex items-center justify-center"
                >
                  {storeOpen && (
                    <IonIcon
                      icon={caretUp}
                      mode="ios"
                      color="primary"
                    ></IonIcon>
                  )}
                  {!storeOpen && (
                    <IonIcon
                      icon={caretDown}
                      mode="ios"
                      color="primary"
                    ></IonIcon>
                  )}
                </IonCol>
              </IonRow>
              {storeOpen && (
                <div
                  className={
                    classes.content + ' w-full h-auto flex px-4 flex-col'
                  }
                >
                  <Link
                    className="w-full h-auto my-0 py-2"
                    to={{
                      pathname: `/${item?.store?.slug}`,
                      state: { id: item?.store?.id },
                    }}
                  >
                    {item?.store?.name}
                  </Link>
                  <div
                    className={
                      classes.locations + ' w-full h-auto flex flex-col '
                    }
                  >
                    <div className={classes.label + ' w-full h-auto'}>
                      Locations
                    </div>
                    <div
                      className={
                        classes.list +
                        ' w-full h-auto mb-1 pl-2 flex flex-col items-start'
                      }
                    >
                      {item.store?.location!.split(',').map((s, idx) => {
                        return (
                          <div key={idx} className={classes.item + ' w-full h-auto py-2'}>
                            {s}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={
                      classes.contacts + ' w-full h-auto flex flex-col '
                    }
                  >
                    <div className={classes.label + ' w-full h-auto'}>
                      Contacts
                    </div>
                    <div
                      className={
                        classes.list +
                        ' w-full h-auto mb-1 pl-2 flex flex-col items-start'
                      }
                    >
                      {item.store?.phoneNumber!.split(',').map((s, idx) => {
                        return (
                          <div key={idx} className={classes.item + ' w-full h-auto py-2'}>
                            {s}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonCard>
  );
};

export default ProductCard;
