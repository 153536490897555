import Layout from "../../../components/Layout/admin";
import Header from "../header";
import classes from "./styles.module.scss";
import { HiChevronDown } from "react-icons/hi";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner";
import ErrorToast from "../../../components/error_toast";
import { GlobalContext } from "../../../context/provider";
import { getAdminUsers } from "../../../context/actions/admin/user";
import {
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_ERROR,
} from "../../../context/actions/types";

interface Admin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  createdAt: string;
}

const AdminUsers = () => {
  const { adminUserState, adminUserDispatch } = useContext<any>(GlobalContext);
  const [users, setUsers] = useState<Admin[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchadminUsers();
  }, []);
  useEffect(() => {
    if (adminUserState?.adminUsers) {
      const users = adminUserState?.adminUsers;
      setUsers(users);
    }
    if (adminUserState?.adminUsersError) {
      setIsErrorOpen(true);
      setErrorMessage(adminUserState?.adminUsersError);
      adminUserDispatch({ type: GET_ADMIN_USERS_ERROR, payload: null });
    }
  }, [adminUserState, adminUserDispatch]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      weekday: "short",
    });
  };

  const fetchadminUsers = async () => {
    setLoading(true);
    await getAdminUsers()(adminUserDispatch);
    setLoading(false);
  };

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={"error"}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div className={classes.header}>
        <Header title="Users" />
      </div>
      <div className={classes.table}>
        <table>
          <thead>
            <tr>
              <th className={classes.id}> ID</th>
              <th className={classes.name}>
                <div className={classes.drop_down}>
                  <HiChevronDown />
                  <p> Name</p>
                </div>
              </th>
              <th className={classes.type}>Role</th>
              <th>Email</th>
              <th className={classes.created}>Created On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td className={classes.input_sec}>
                    <input type="checkbox" />
                    {user.id}
                  </td>
                  <td className={classes.name}><p>{`${user.firstName} ${user.lastName}`}</p></td>
                  <td className={classes.type}>{user.role}</td>
                  <td>{user.email}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>
                    {/* <Link to={`/admin/dashboard/users/${user.id}`}> */}
                    <button className={classes.view}>View</button>
                    {/* </Link> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className={classes.footer}>Showing: {users.length} users</tr>
          </tfoot>
        </table>
        {loading && (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AdminUsers;
