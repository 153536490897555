import { IonGrid, IonRow, IonCol, RefresherEventDetail } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import StoreCard from '../../../components/cards/store';
import Filterbar from '../../../components/filterbar';
import Layout from '../../../components/Layout/user/Layout';
import { getStores } from '../../../context/actions/store';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { GET_STORES_ERROR } from '../../../context/actions/types';
import { storeSchema } from '../../../types/store';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import useGlobalContext from '../../../context/provider';

const Stores = () => {
  const size = useContext(DeviceContext);
  const [stores, setStores] = useState<storeSchema[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { storeState, storeDispatch } = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    async function fetch() {
      if (storeState!.bulkStores) {
        setLoading(false);
        return setStores(storeState!.bulkStores as storeSchema[]);
      }
      await getStores()(storeDispatch!);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (storeState!.bulkStores) {
      setStores(storeState!.bulkStores as storeSchema[]);
      setLoading(false);
    }
    if (storeState!.bulkStoresError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState!.bulkStoresError!?.response!?.data!?.message
          ? storeState!.bulkStoresError!?.response!?.data!?.message
          : storeState!.bulkStoresError!?.message
      );

      storeDispatch!({ type: GET_STORES_ERROR, payload: null });
    }
  }, [storeState]);

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async () => {
      await getStores()(storeDispatch!);
      e.detail.complete();
    }, 1000);
  };

  return (
    <Layout
      enableScrollToToP={true}
      enableRefresh={true}
      handleRefresh={handleRefresh}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />

      <div className='w-full px-3 py-2 md:px-5 '>
        <div className='w-full'>
          <div className='text-primary font-semibold text-lg my-5'>Shops</div>
          {/* <div
            className={classes.filterBar}
            style={{ width: size.width >= 568 ? '20%' : '60%' }}
          >
            <Filterbar action={setFilters} />
          </div> */}
        </div>
        <div className='mt-4 grid grid-cols-2 gap-1  md:grid-cols-5 md:gap-2'>
          {loading ? (
            <Spinner type="circles"></Spinner>
          ) : (
            stores.map((item, index) => {
              return <StoreCard data={item} key={index} />;
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Stores;
