import { useEffect, useState, useContext } from 'react';
import classes from './styles.module.scss';
import {
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { useHistory, useLocation } from 'react-router';
import {
  caretForwardSharp,
  fileTraySharp,
  gridSharp,
  logOutSharp,
  settingsSharp,
  storefrontSharp,
  peopleSharp,
  caretDownSharp,
  closeOutline,
} from 'ionicons/icons';
import { User } from '../../../../types/user';
import { isLoggedIn } from '../../../../utils/isLoggedIn';
import { authService } from '../../../../utils/authService';
import { DeviceContext } from '../../../../deviceType';


const AdminMenu = ({ adminDetails }: { adminDetails: User }) => {
  
  return (
    <>
      <AdminMenuContent
        adminDetails={adminDetails}
        showCloseButton={false}
      />
    </>
  );
};

export default AdminMenu;

export interface AdminMenuContentProps {
  adminDetails: User;
  action?: any;
  showCloseButton: boolean;
}
export const AdminMenuContent = ({ adminDetails, action=null, showCloseButton = false }: AdminMenuContentProps) => {
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const history = useHistory();
  const device = useContext(DeviceContext);
  const location = useLocation();
  const loggedIn = isLoggedIn();
  const [openApprovalsMenu, setOpenApprovalsMenu] = useState<boolean>(false);

  const closeMenu = async () => {
    if (action) {
      await action.close();
    }
  };

  useEffect(() => {
    if (location.pathname) {
      setCurrentRoute(location.pathname);
    }
  }, [location]);
  
  return (
    <div className={classes["content-wrapper"]}>
      <IonRow className={`${classes['white-bg']} ${device.width < 769 ? classes['m-top'] : ''}`}>
        <IonCol size={showCloseButton ? "10" : "12" }className={classes['logo-col']}>
          <div
            className={classes['title-wrapper']}
            onClick={() => {
              history.push('/admin/dashboard/home');
            }}
          >
            <IonIcon className={classes.logo} src={logo}></IonIcon>
            <span className={classes.primary}>Bazaar</span>
            <span className={classes.secondary}>Cheap</span>
            <span className={classes.admin}>&#124; Admin</span>
          </div>
        </IonCol>
        <IonCol size="2" className={classes['close-col']}>
          {showCloseButton && (
            <IonIcon
              className={classes.close}
              icon={closeOutline}
              onClick={async() => {
                await closeMenu();
              }}
            />
          )}
        </IonCol>
      </IonRow>

      <IonRow
        className={`${classes['user-details-row']} ${classes['white-bg']}`}
        onClick={async() => {
          await closeMenu(); // close menu after click
          if (loggedIn) {
            history.push('/account');
          } else {
            history.push('/login');
          }
        }}
      >
        <IonCol>
          <IonItem lines='none' detail={false}>
            <div>{adminDetails?.firstName?.[0]}{adminDetails?.lastName?.[0]}</div>

            <IonLabel color='primary'>
              {adminDetails?.firstName}{' '}
              {adminDetails?.lastName}
            </IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>

      <IonList className={classes['menu-items']}>
        {routes.map((item, index) => {
          if (item.section !== 'approval') {
            return (
              <IonItem
                key={index}
                lines="none"
                detail={false}
                button
                onClick={async() => {
                  await closeMenu(); // close menu after click
                  if (item.isLogOut) {
                    authService.logoutUser();
                    history.push('/admin/login');
                  } else {
                    history.push(item.path);
                  }
                }}
                className={`${currentRoute === item.path ? classes.selected : ''} ${item.path === '/admin/dashboard/settings' ? classes['last-item'] : ''}`}
              >
                <IonIcon slot="start" icon={item.icon} />
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            );
          } else {
            return (
              <div key={index} className={classes['approvals-wrapper']}>
                <hr className={classes.divider}/>
                <IonItem
                  lines='none'
                  detail={false}
                  button
                  onClick={() => {
                    setOpenApprovalsMenu(!openApprovalsMenu);
                  }}
                  className={classes['approval-item']}
                >
                  <IonIcon slot='start' icon={openApprovalsMenu ? caretDownSharp : caretForwardSharp} />
                  <IonLabel>Approvals</IonLabel>
                </IonItem>

                {openApprovalsMenu && (
                  <div className={classes["approvals-menu"]}>
                    {approvalRoutes.map((route, idx) => {
                      return (
                        <IonItem
                          key={idx}
                          lines="none"
                          detail={false}
                          button
                          onClick={async() => {
                            await closeMenu(); // close menu after click
                            history.push(route.path);
                          }}
                          className={`approval-item ${currentRoute === route.path ? classes.selected : ''}`}
                        >
                          <IonIcon slot="start" icon={route.icon} />
                          <IonLabel>{route.name}</IonLabel>
                        </IonItem>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          }
        })}
      </IonList>
    </div>
  );
};

export const routes: {
  path: string;
  name: string;
  section: 'main' | 'approval' | 'bottom';
  icon: any;
  isLogOut?: boolean;
}[] = [
  {
    path: '/admin/dashboard/home',
    name: 'Home',
    section: 'main',
    icon: gridSharp,
  },
  {
    path: '/admin/dashboard/stores',
    name: 'Stores',
    section: 'main',
    icon: storefrontSharp,
  },
  {
    path: '/admin/dashboard/products',
    name: 'Products',
    section: 'main',
    icon: fileTraySharp,
  },
  {
    path: '/admin/dashboard/users',
    name: 'Users',
    section: 'main',
    icon: peopleSharp,
  },
  {
    path: '#',
    name: 'Approvals',
    section: 'approval',
    icon: caretForwardSharp,
  },
  {
    path: '/admin/dashboard/settings',
    name: 'Settings',
    section: 'bottom',
    icon: settingsSharp,
  },
  {
    path: '/admin/login',
    name: 'LogOut',
    section: 'bottom',
    isLogOut: true,
    icon: logOutSharp,
  },
];

export const approvalRoutes: {
  path: string;
  name: string;
  section: 'approval';
  icon: any;
  isLogOut?: boolean;
}[] = [
  {
    path: '/admin/dashboard/approvals/stores',
    name: 'Stores',
    section: 'approval',
    icon: storefrontSharp,
  },
  {
    path: '/admin/dashboard/approvals/products',
    name: 'Products',
    section: 'approval',
    icon: fileTraySharp,
  },
];

