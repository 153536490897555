export const getTotalProductLikes = (products: any[]) => {
  let productLikesCount = 0;
  for (let i = 0; i < products?.length; i++) {
    const likes = products[i].bookmarks!.length || 0;
    productLikesCount += likes ;
  }

  return productLikesCount;
};

export const getProductStatus = (status: string) => {
  if (status === 'pending') {
    status = 'Pending Approval';
  }

  return status;
};
