/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  useIonActionSheet,
  IonRippleEffect,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import useGlobalContext from '../../../context/provider';
import {
  deleteProducts,
  getSingleProduct,
} from '../../../context/actions/products';
import {
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
  GET_STORE,
} from '../../../context/actions/types';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import ProductCard from '../../user/product-details/productCard';
import { closeSharp, ellipsisVertical, trashBinSharp } from 'ionicons/icons';
import GenericActionModal from '../../../components/genericActionModal';
import classNames from 'classnames';
import { getStore } from '../../../context/actions/store';
import { getCurrentStore } from '../../../utils/currentStore';

const ProductSeller = () => {
  const size = useContext(DeviceContext);
  const location = useLocation();
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const { productState, productDispatch, storeDispatch, storeState } =
    useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const [present] = useIonActionSheet();
  const status = [
    {
      message: 'pending',
      status: 'pending',
      colors: { text: '#D68800', background: '#FFE9D2' },
    },
    {
      message: 'published',
      status: 'published',
      colors: { background: '#9EBFF8', text: '#0E6FD4' },
    },
    {
      message: 'approved',
      status: 'approved',
      colors: { text: '#28A745', background: '#BCEFC8' },
    },
    {
      message: 'rejected',
      status: 'rejected',
      colors: { text: '#CC292A', background: '#F09CA4' },
    },
  ];
  const options = [
    {
      name: 'Edit',
      action: () => {
        setOpenMenu(!openMenu);
        history.push({
          pathname: `/dashboard/edit-product/detail?q=${id}&i=${item.name.slice(
            0,
            30
          )}`,
          state: { id },
        });
        destroy();
      },
    },
    {
      name: 'Delete',
      action: () => {
        setOpenMenu(!openMenu);
        setDeleteModalOpen(true);
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const _id2 = location.pathname
      .split('?')
      .slice(-1)[0]
      .split('&')[0]
      .split('=')
      .slice(-1)[0];
    const _id: any = location.state ? location.state : null;
    if (_id) {
      setId(`${_id.id}`);
      fetchItem(_id.id);
    } else if (_id2) {
      setId(_id2);
      fetchItem(Number(_id2));
    }
  }, []);

  useEffect(() => {
    if (productState.getSingleProduct) {
      const product = productState.getSingleProduct;
      if (product.id === Number(id)) {
        setItem(product);
        setLoading(false);
      }
    }
    if (productState?.getSingleProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.getSingleProductError!?.response!?.data!?.message
          ? productState?.getSingleProductError!?.response!?.data!?.message
          : productState?.getSingleProductError!?.message
      );
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
      setLoading(false);
    }
  }, [productState?.getSingleProduct, productState?.getSingleProductError]);

  useEffect(() => {
    if (productState?.deleteProduct) {
      storeDispatch({ type: GET_STORE, payload: null });
      async () => await getStoreInfo();
      productDispatch({ type: DELETE_PRODUCT, payload: null });
      history.push('/dashboard/products');
      setLoading(false);
    }
    if (productState?.deleteProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.deleteProductError!?.response!?.data!?.message
          ? productState?.deleteProductError!?.response!?.data!?.message
          : productState?.deleteProductError!?.message
      );
      productDispatch({ type: DELETE_PRODUCT_ERROR, payload: null });
      setLoading(false);
    }
  }, [productState?.deleteProduct, productState?.deleteProductError]);

  useEffect(() => {
    if (item.id) setLoading(false);
  }, [item]);

  async function fetchItem(id: number) {
    if (productState?.getSingleProduct?.id === id) {
      const product = productState.getSingleProduct;
      setItem(product);
      return;
    }
    await getSingleProduct(id)(productDispatch);
  }
  const destroy = () => {
    setItem({} as ProductType);
    setId('');
  };
  async function deletedata(id: string) {
    setDeleteModalOpen(false);
    setLoading(true);
    await deleteProducts({ id })(productDispatch);
  }
  async function getStoreInfo() {
    const currentStore = getCurrentStore();
    await getStore(Number(currentStore))(storeDispatch);
  }
  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <GenericActionModal
        title="Delete Product"
        trigger="delete-modal-trigger"
        open={deleteModalOpen}
        width={classNames({
          '25dvw': size.width >= 768,
          '80dvw': size.width < 768,
        })}
        onDismiss={() => {
          setDeleteModalOpen(false);
        }}
      >
        <div className='w-full h-auto py-2 px-4 text-center  text-gray-900 text-sm font-semibold font-["Roboto",sans-serif]'>
          Are you sure you want to delete this product?
        </div>
        <div className='w-full h-auto text-center  pb-2 px-4 text-[var(--ion-color-primary)] text-sm font-base font-["Roboto",sans-serif]'>
          This action cannot be reversed.
        </div>
        <div className="w-full h-auto flex items-center justify-between border-t-2 py-4">
          <IonButton
            // color="light"
            fill="solid"
            style={{ '--border-radius': '10px', '--background':'rgba(0,0,0,0.3)'  }}
            onClick={() => {
              setDeleteModalOpen(false);
            }}
            className="m-0 mr-2 w-[48%]"
          >
            Cancel{' '}
            <IonIcon icon={closeSharp} slot="start" size="small"></IonIcon>
          </IonButton>
          <IonButton
            color="danger"
            fill="solid"
            onClick={() => {
              deletedata(item.id!.toString());
            }}
            className="m-0 mr-2 w-[48%] "
            style={{ '--border-radius': '10px' }}
          >
            Delete
            <IonIcon icon={trashBinSharp} slot="start" size="small"></IonIcon>
          </IonButton>
        </div>
      </GenericActionModal>
    
      <div className="w-full h-auto" key={id}>
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="crescent"></Spinner>
          </div>
        ) : (
          <IonGrid className={'size-full p-0 m-0 bg-transparent'}>
            <IonRow className={classes.container + ' size-full relative flex'}>
              <IonCol
                className={' relative size-full px-0 md:px-[10dvh] overflow-y-scroll'}
                size={'12'}
              >
                <div className={' py-2 size-full'}>
                  <div className="w-full h-auto flex flex-row items-center justify-end">
                    <IonButton
                      fill="clear"
                      color="primary"
                      onClick={() => {
                        setOpenMenu(true);
                      }}
                      style={{
                        '--color': 'var(--ion-color-primary)',
                        '--padding-start': '0.8em',
                        '--padding-end': '0.8em',
                      }}
                      size="default"
                    >
                      <IonIcon
                        slot="icon-only"
                        size="small"
                        icon={ellipsisVertical}
                      ></IonIcon>
                    </IonButton>
                  </div>
                  {openMenu && (
                    <div
                      className={`focus:outline-none focus:shadow-none before:fixed before:z-10 before:top-0 before:right-0 before:bottom-0 before:left-0 before:block before:cursor-pointer before:bg-[rgba(0,0,0,0.1)] before:content-[" "] before:col-span-1`}
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <div
                        className={
                          'w-auto min-w-[35dvw] md:min-w-[10dvw] h-fit absolute z-20 bg-white shadow-sm rounded-lg right-[8%] px-4 py-2'
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ul>
                          {options.map((item, index) => {
                            return (
                              <div
                                className={
                                  ' hover:bg-gray-50 ion-activatable ripple-parent circle py-2 text-[var(--ion-color-primary)] text-lg font-["Roboto", sans-serif] font-base cursor-pointer'
                                }
                                key={index}
                                id={classNames({
                                  'delete-modal-trigger': item.name
                                    .toLocaleLowerCase()
                                    .includes('delete'),
                                })}
                                onClick={() => {
                                  item.action();
                                }}
                              >
                                <IonRippleEffect></IonRippleEffect>
                                {item.name}
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  <ProductCard
                    data={item}
                    disableWishlist={true}
                    isVendor={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
    </Layout>
  );
};

export default ProductSeller;
