import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { IonCol, IonGrid, IonRow, RefresherEventDetail } from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import StoreBar from '../../../components/storeBar';
import useGlobalContext from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import PChart from '../../../components/pieChart';
import { ProductType } from '../../../types/product';
import BChart from '../../../components/barChart';
import MultipleTabCard from '../../../components/multipleTabCard';
import { Store, storeSchema } from '../../../types/store';
import CurrentStoreCard from '../../../components/currentstorecard';
import { getCurrentStore } from '../../../utils/currentStore';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import { getCurrentUser } from '../../../utils/getClientInfo';

const SellerHome = () => {
  const currentStore = getCurrentStore();
  const size = useContext(DeviceContext);
  const { storeState, storeDispatch } = useGlobalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [store, setStore] = useState<storeSchema>({ } as storeSchema);
  const [categoryEngagements, setCategoryEngagements] = useState<
    { name: string; value: number }[]
  >([]);
  const [views, setViews] = useState<
    { id: number | string; product: any; createdAt: string }[]
  >([]);
  const [mostViewed, setMostViewed] = useState<ProductType[]>([]);
  const [bookmarks, setBookmarks] = useState<ProductType[]>([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // this listens for change in the localstorage
    setLoading(true);
    const currentStore = getCurrentStore();
    window.addEventListener('storage', () => {
      setLoading(true);
      fetchStore(currentStore as string);
    });
    fetchStore(currentStore as string);
  }, []);

  useEffect(() => {
    const _products =
      store.products!?.filter((i, index) => i.status === 'published') || [];
    const _bookmarks = [..._products].sort((a: ProductType, b: ProductType) => {
      if (a.bookmarks!.length < b.bookmarks!.length) {
        return 1;
      }
      if (a.bookmarks!.length > b.bookmarks!.length) {
        return -1;
      }
      return 0;
    });
    const mostviewed = [..._products].sort((a: ProductType, b: ProductType) => {
      if (a.views!.length! < b.views!.length!) {
        return 1;
      }
      if (a.views!.length! > b.views!.length!) {
        return -1;
      }
      return 0;
    });
    setViews(store.productViews! || []); // views are set
    setMostViewed(mostviewed);
    setBookmarks(_bookmarks);
    const categoryEngagements = extractCategoryEngagements(store.productViews!);
    setCategoryEngagements(categoryEngagements);

    if (store.id?.toString() === currentStore) {
      setLoading(false);
    }
  }, [store]);

  useEffect(() => {
    if (storeState.store) {
      const _store = storeState.store;
      setStore(_store);
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState]);

  // functions
  const extractCategoryEngagements = (viewList: any[] = []) => {
    let list: { name: string; value: number }[] = [];
    for (let i = 0; i < viewList.length; i++) {
      if (list.some((e) => e.name === viewList[i].product.type)) {
        const index = list.findIndex(
          (e) => e.name === viewList[i].product.type
        );
        const group = {
          name: list[index].name,
          value: list[index].value + 1,
        };
        list[index] = group;
      } else {
        const group = {
          name: viewList[i].product.type,
          value: 1,
        };
        list = [...list, group];
      }
    }
    return list;
  };

  async function fetchStore(_id: string) {
    setLoading(true);
    let store = storeState.store as storeSchema;
    if (store && store?.id?.toString() === currentStore) {
      setStore(store);
      return;
    }

    await getStore(Number(_id))(storeDispatch);
  }

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async () => {
      await fetchStore(getCurrentStore() as string);
      e.detail.complete();
    }, 1000);
  };

  return (
    <Layout
      enableScrollToToP={true}
      enableRefresh={true}
      handleRefresh={handleRefresh}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />

      {loading ? (
        <div className={'w-full h-[90dvh] flex items-center justify-center'}>
          <Spinner type={'circular'} />
        </div>
      ) : (
        <div className={classes.holder + '  !overflow-hidden'}>
          <div className="w-full h-full grid grid-cols-10  gap-2 py-4 !overflow-hidden">
            <div className="h-full col-span-10 md:col-span-7 flex flex-col items-center justify-start overflow-y-scroll px-4 md:px-2 ">
              {/* analytics graphs */}
              <div
                className={
                  'w-full h-full grid grid-cols-5 grid-flow-row-dense gap-2 mb-2 '
                }
              >
                <div className="col-span-5 md:col-span-3 h-[65dvh] md:h-[70dvh] flex flex-col items-center justify-start bg-white rounded-3xl mb-4 p-4">
                  <div className="w-full h-[5%] flex items-center text-primary text-lg font-roboto font-medium">
                    Product Views
                  </div>
                  <div className="w-full h-[95%] flex items-center justify-center ">
                    <BChart
                      errorMessage="No Previous Week Data To Show"
                      data={views}
                      YAxisLabel="Views"
                    />
                  </div>
                </div>
                <div className="col-span-5 md:col-span-2 h-[90dvh] md:h-[70dvh] flex flex-col items-center justify-start bg-white rounded-3xl mb-4 p-4 ">
                  <div className="w-full h-[5%] flex items-center text-primary text-lg font-roboto font-medium">
                    Engagement By Category
                  </div>
                  <div className="w-full h-[90%] flex items-center justify-center ">
                    <PChart data={categoryEngagements} />
                  </div>
                </div>
              </div>
              {/* tab card */}
              <div className="w-full h-auto flex items-center justify-center ">
                <MultipleTabCard
                  data={[
                    {
                      label: 'Likes',
                      default: true,
                      data: bookmarks,
                      type: 'likes',
                    },
                    {
                      label: 'Views',
                      data: mostViewed,
                      type: 'views',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="h-auto col-span-10 md:col-span-3 md:flex flex-col items-center justify-start hidden ">
              <StoreBar />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SellerHome;
