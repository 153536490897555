/* eslint-disable no-restricted-globals */
import {
  IonAvatar,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import {
  closeOutline,
  chevronDownOutline,
  chevronUpOutline,
  personSharp,
} from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { otherRoutes } from '../../utils/user/routes';
import classes from './styles.module.scss';
import { isLoggedIn } from '../../utils/isLoggedIn';
import { GlobalContext } from '../../context/provider';
import logo from '../../Assets/logo/logo_clear_96x96.svg';
import { authService } from '../../utils/authService';
import UserProfile from '../storeMenu/userProfile';
import BecomeASellerModal from '../becomeSellerModal';

const UserSlideMenu = ({ action, user }: { action: any; user: any }) => {
  const { userState } = useContext<any>(GlobalContext);
  const userDetails = userState.getUser;
  const location = useLocation();
  const history = useHistory();
  const loggedIn = isLoggedIn();
  const [currentPath, setCurrentPath] = useState<string>('');
  const [menuItemIndex, setMenuItemIndex] = useState<number | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let userProfileplaceholder =
    userDetails?.firstName?.[0]?.toUpperCase() +
    ' ' +
    userDetails?.lastName?.[0]?.toUpperCase();
  const handleOpenSubMenu = (index: number) => {
    if (index === menuItemIndex) {
      setMenuItemIndex(undefined);
    } else {
      setMenuItemIndex(index);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    setCurrentPath(path);

    // TODO: refactor when child paths are updated
    // const pathToCompare = path.split('/detail')[0];
    // if (productPaths.some((str) => str.includes(pathToCompare))) {
    // 	setCurrentPath('/products');
    // }
    // if (storePaths.some((str) => str.includes(pathToCompare))) {
    // 	setCurrentPath('/stores');
    // }
  }, [location?.pathname]);

  const handleRedirect = (item: any) => {
    action.close();
    if (item.route === '/logout') {
      return authService.logoutUser();
    }
    if (item.isSwitch) {
      return setIsModalOpen(true);
    }
    history.push(item.route);
  };
  return (
    <div className={classes['user-slidemenu']}>
      <BecomeASellerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

      <div className={classes.header}>
        <IonRow className={classes['logo-close-row']}>
          <IonCol size="10">
            <div
              className={classes.logo}
              onClick={async () => {
                await action.close(); // close menu after click
                history.push('');
              }}
            >
              <IonIcon src={logo}></IonIcon>
              <span className={classes.span1}>Bazaar</span>{' '}
              <span className={classes.span2}>Cheap</span>
            </div>
          </IonCol>
          <IonCol size="2">
            <div
              className={
                classes.close + ' ion-activatable ripple-parent circle'
              }
              onClick={async () => {
                await action.close();
              }}
            >
              <IonRippleEffect></IonRippleEffect>
              <IonIcon icon={closeOutline} color={'secondary'}></IonIcon>
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          className={classes['user-details-row']}
          onClick={async () => {
            await action.close(); // close menu after click
            if (loggedIn) {
              history.push('/account');
            } else {
              history.push('/login');
            }
          }}
        >
          <IonCol>
            {!userDetails ? (
              <>
                <IonLoading
                  isOpen
                  message="loading..."
                  duration={1000}
                ></IonLoading>
                <IonItem lines="none" detail={false}>
                  <IonIcon slot="start" icon={personSharp}></IonIcon>
                  <IonLabel color="secondary">Log In / Sign Up</IonLabel>
                </IonItem>
              </>
            ) : (
              <>
                <IonItem lines="none" detail={false}>
                  <IonAvatar slot="start">
                    <UserProfile
                      color="#A94064"
                      placeholder={userProfileplaceholder}
                    />
                  </IonAvatar>
                  <IonLabel className="text-base text-primary !font-normal font-roboto">
                    {userDetails?.firstName.charAt(0).toUpperCase() +
                      userDetails?.firstName.slice(1)}{' '}
                    {userDetails?.lastName.charAt(0).toUpperCase() +
                      userDetails?.lastName.slice(1)}
                  </IonLabel>
                </IonItem>
              </>
            )}
          </IonCol>
        </IonRow>
      </div>

      <div className={classes['menu-items']}>
        {otherRoutes.map((item, index) => {
          if (
            item.disabled ||
            (item.isSeller && !user) ||
            (item.isSeller && user?.role !== 'seller') ||
            (item.isSwitch && user?.role === 'seller') ||
            (item.isSwitch && !user)
          )
            return null;
          return (
            <div key={index} className={classes['menu-item-wrapper']}>
              {!item.children ? (
                <IonItem
                  lines="none"
                  detail={false}
                  button
                  onClick={() => handleRedirect(item)}
                  className={`menu-item ${
                    currentPath === item.route ? classes.selected : ''
                  }`}
                  style={{
                    display:
                      item.route === '/login' && !loggedIn && !userDetails
                        ? 'none'
                        : 'block',
                  }}
                >
                  <IonIcon slot="start" icon={item.icon} />
                  <IonLabel className="font-roboto !font-normal">
                    {item.name}
                  </IonLabel>
                </IonItem>
              ) : (
                <>
                  <IonItem
                    lines={menuItemIndex === index ? 'full' : 'none'}
                    detail={false}
                    button
                    onClick={() => handleOpenSubMenu(index)}
                    className={`menu-item ${
                      currentPath === item.route ? classes.selected : ''
                    }`}
                  >
                    <IonIcon slot="start" icon={item.icon} />
                    <IonLabel>{item.name}</IonLabel>
                    <IonIcon
                      slot="end"
                      icon={
                        menuItemIndex === index
                          ? chevronUpOutline
                          : chevronDownOutline
                      }
                      className={classes.chevron}
                    />
                  </IonItem>
                  {index === menuItemIndex && (
                    <IonList className={classes['sub-menu-list']}>
                      {item.children.map((child, idx) => {
                        return (
                          <IonItem
                            key={idx}
                            lines="none"
                            detail={false}
                            button
                            routerDirection="root"
                            routerLink={child.route}
                            onClick={async () => {
                              await action.close();
                              history.push({ pathname: child.route });
                            }}
                            className={classes['sub-menu-item']}
                          >
                            <IonIcon slot="start" icon={item.icon} />
                            <IonLabel className={classes.label}>
                              {child.name}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonList>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>

      <div className={classes.menuFooter}>
        Copyright &copy; Bazaar Cheap {new Date().getFullYear()} | All Rights
        Reserved
      </div>
    </div>
  );
};

export default UserSlideMenu;
