import React, { useContext } from 'react';
import classes from './styles.module.scss';
import {
  IonToolbar,
  IonRow,
  IonCol,
  IonMenuButton,
  IonHeader,
  IonIcon,
} from '@ionic/react';
import { User } from '../../../../types/user';
import { menuController } from '@ionic/core';
import { useHistory } from 'react-router-dom';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { DeviceContext } from '../../../../deviceType';


const AdminNav = ({ adminDetails }: { adminDetails: User }) => {
  const device = useContext(DeviceContext);
  const history = useHistory();
  
  return (
    <IonHeader className={classes['admin-nav']}>
      
      {device.width < 769 && (
        <IonToolbar className={classes['bottom-toolbar']}>
          <IonRow>
            <IonCol size="10" className={classes['logo-col']}>
              <div
                className={classes['title-wrapper']}
                onClick={() => {
                  history.push('/admin/dashboard/home');
                }}
              >
              <IonIcon className={classes.logo} src={logo}></IonIcon>
              <span className={classes.primary}>Bazaar</span>
              <span className={classes.secondary}>Cheap</span>
              <span className={classes.admin}>&#124; Admin</span>
              </div>
            </IonCol>
            <IonCol size="2" className={classes['menu-col']}>
              <IonMenuButton
                color={'primary'}
                onClick={async() => {
                  await menuController.open('menu');
                }}
              />
            </IonCol>
          </IonRow>
        </IonToolbar>
      )}
    </IonHeader>
  );
};

export default AdminNav;
