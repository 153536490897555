import { useEffect, useState } from 'react';
import { ProductType } from '../../../types/product';
import { storeSchema } from '../../../types/store';
import ProductBlock from '../../../components/cards/Product_Block';
import InputCard from '../../../components/inputField';
import {  FiFilter } from 'react-icons/fi';
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonModal,
  IonRippleEffect,
} from '@ionic/react';
import useGlobalContext from '../../../hooks/useStore';
import { getProductTypes } from '../../../context/actions/products';
import ErrorToast from '../../../components/error_toast';
import { GET_PRODUCT_TYPES_ERROR } from '../../../context/actions/types';
import { closeSharp } from 'ionicons/icons';
import { IoIosArrowForward } from 'react-icons/io';

const StoreProducts = ({
  products,
  store,
}: {
  products: ProductType[];
  store: storeSchema;
}) => {
  const globalState = useGlobalContext();
  const [productCategories, setProductCategories] = useState<string[]>([]);
  const [search, setSearch] = useState<{ query: string }>({ query: '' });
  const [filtered, setFiltered] = useState<ProductType[]>([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [priceRange, setPriceRange] = useState<{ min?: number; max?: number }>(
    {}
  );
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    categories?: string[] | null;
    price?: { min: number; max: number } | null;
  }>({});
  const [filterCount, setFilterCount] = useState<number>(0);

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    setFiltered([...products]);
  }, [products]);

  useEffect(() => {
    if (globalState.productTypes.productTypes) {
      setProductCategories(
        globalState.productTypes.productTypes.data as string[]
      );
    }
    if (globalState.productTypes.productTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        globalState.productTypes.productTypesError!?.response!?.data!?.message
          ? globalState.productTypes.productTypesError!?.response!?.data!
              ?.message
          : globalState.productTypes.productTypesError!?.message
      );
      globalState.productTypesDispatch({
        type: GET_PRODUCT_TYPES_ERROR,
        payload: null,
      });
    }
  }, [globalState.productTypes, globalState.productTypesDispatch]);

  useEffect(() => {
    if (search.query) {
      let _filtered = filterOnSearch(products);
      if (filters.categories || filters.price) {
        _filtered = filterProducts(_filtered);
      }
      setFiltered(_filtered);
    } else {
      let _filtered = products;
      if (filters.categories || filters.price) {
        _filtered = filterProducts(_filtered);
      }
      setFiltered(_filtered);
    }
  }, [search]);

  useEffect(() => {
    if (filters.categories || filters.price) {
      let _filtered = filterProducts(products);
      if (search.query) {
        _filtered = filterOnSearch(_filtered);
      }
      let count: number = filters.categories!.length;
      if (filters.price?.max || filters.price?.min) {
        count++;
      }
      setFilterCount(count);
      setFiltered(_filtered);
    } else {
      let _filtered = products;
      if (search.query) {
        _filtered = filterOnSearch(_filtered);
      }
      setFiltered(_filtered);
    }
  }, [filters]);

  const fetchTypes = async () => {
    if (globalState?.productTypes.productTypes) {
      setProductCategories(
        globalState.productTypes.productTypes.data as string[]
      );
      return;
    }
    await getProductTypes()(globalState.productTypesDispatch);
    return;
  };
  const handleCheckBoxClick = (item: string) => {
    if (filters.categories && filters.categories!.includes(item)) {
      setFilters({
        ...filters,
        categories: filters.categories!.filter((i) => i !== item),
      });
    } else {
      if (filters.categories) {
        return setFilters({
          ...filters,
          categories: [...filters.categories!, item],
        });
      }
      setFilters({
        ...filters,
        categories: [item],
      });
    }
  };
  const filterProducts = (list: ProductType[]) => {
    let _filtered = list;
    if (filters.categories && filters.categories.length) {
      _filtered = list.filter((item) =>
        filters.categories!.includes(item.type)
      );
    }
    if (filters.price) {
      _filtered = _filtered
        .filter((item: ProductType) =>
          filters.price!.min ? item.price >= filters.price!.min : true
        )
        .filter((item) =>
          filters.price!.max ? item.price <= filters.price!.max : true
        );
    }
    return _filtered;
  };
  const filterOnSearch = (list: ProductType[]) => {
    const _filtered = list.filter(
      (item) =>
        item.name
          .toLocaleLowerCase()
          .includes(search.query.toLocaleLowerCase()) ||
        item
          .details!.toLocaleLowerCase()
          .includes(search.query.toLocaleLowerCase()) ||
        item.type.toLocaleLowerCase().includes(search.query.toLocaleLowerCase())
    );
    return _filtered;
  };
  return (
    <div className="w-full md:w-[85dvw] mx-auto min-h-[50dvh] px-2 mt-2 flex items-start ">
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <IonModal
        trigger="filter-modal-trigger"
        isOpen={filterModalOpen}
        style={{
          '--width': '100dvw',
          '--height': '100dvh',
          '--max-width': '100dvw',
          '--max-height': '100dvh',
          '--border-radius': 'none',
        }}
        backdropDismiss
        animated
      >
        <div className={`w-full h-full px-4 py-6 flex flex-col`}>
          <div className="w-full h-[1.5em] flex items-center justify-end">
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => {
                setFilterModalOpen(false);
              }}
              className="m-0 h-full"
              size="small"
            >
              <IonIcon
                icon={closeSharp}
                slot="icon-only"
                size="small"
              ></IonIcon>
            </IonButton>
          </div>
          <div className="w-full h-auto flex items-center justify-between ">
            <h1 className="w-auto h-auto text-xl text-primary font-roboto font-semibold flex items-center">
              <span className="mr-2">
                <FiFilter />
              </span>
              Filters
            </h1>
          </div>
          <div className="w-full h-auto mt-2 flex flex-col items-start">
            <div className="w-full flex h-auto items-center justify-between">
              <h2 className="w-full h-auto text-lg text-primary font-roboto font-semibold">
                Categories
              </h2>
              <IonButton
              className=" text-primary-tint text-base font-normal"
                fill="clear"
                size="small"
                onClick={() => {
                  setFilters({ ...filters, categories: [] });
                }}
                style={{
                  '--border-radius': '10px',
                }}
              >
                Clear
              </IonButton>
            </div>
            <div className="w-full h-auto max-h-[40dvh] overflow-y-scroll mt-2 flex flex-col items-start px-2">
              {productCategories.map((item) => {
                return (
                  <div
                    className="w-full h-auto mt-2 flex items-center cursor-pointer"
                    onClick={(e) => {
                      const child = (e.target as any).querySelector(
                        'ion-checkbox'
                      );
                      if (child) {
                        child.click(); // Simulates a click event on the child
                      }
                    }}
                  >
                    <IonCheckbox
                      className="mx-2"
                      value={item}
                      color="primary"
                      checked={
                        filters.categories
                          ? filters.categories!.includes(item)
                          : false
                      }
                      onClick={(e) => handleCheckBoxClick(item)}
                    />
                    <p
                      onClick={(e) => {}}
                      className="w-auto h-auto text-base text-primary font-roboto font-medium"
                    >
                      {item}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full h-auto mt-2 flex flex-col items-start">
            <h2 className="w-full h-auto text-lg text-primary font-roboto font-semibold">
              Price
            </h2>
            <div className="w-full h-auto max-h-[40dvh] overflow-y-scroll mt-2 flex flex-col items-start px-2">
              <div className="w-full h-auto flex items-center justify-between">
                <div className="w-[46%] h-auto flex flex-col items-start">
                  <p className="w-auto mb-[3px] text-primary text-sm  font-medium ml-2">
                    Min
                  </p>
                  <div className="w-full h-[2.5rem] flex items-center">
                    <InputCard
                      inputType="text-field"
                      name="min"
                      placeholder="Minimum Price"
                      required
                      type="number"
                      value={setPriceRange}
                      borders
                      formValues={priceRange}
                    />
                  </div>
                </div>
                <div className="w-[46%] h-auto flex flex-col items-start">
                  <p className="w-auto mb-[3px] text-primary text-sm  font-medium ml-2">
                    Max
                  </p>
                  <div className="w-full h-[2.5rem] flex items-center">
                    <InputCard
                      inputType="text-field"
                      name="max"
                      placeholder="Maximum Price"
                      required
                      type="number"
                      value={setPriceRange}
                      borders
                      formValues={priceRange}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-auto flex items-center justify-between mt-2">
                <IonButton
                  className="w-auto h-[2.5rem] font-thin"
                  color="primary"
                  fill="clear"
                  size="small"
                  disabled={priceRange.max || priceRange.min ? false : true}
                  onClick={() => {
                    setPriceRange({});
                    setFilters({
                      ...filters,
                      price: null,
                    });
                  }}
                  style={{
                    '--border-radius': '10px',
                  }}
                >
                  Clear
                </IonButton>
                <IonButton
                  className="w-auto h-[2.5rem] font-thin"
                  color="secondary"
                  fill="clear"
                  size="small"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      price: {
                        min: Number(
                          priceRange.min!?.toString().replace(/,/g, '')
                        ),
                        max: Number(
                          priceRange.max!?.toString().replace(/,/g, '')
                        ),
                      },
                    });
                  }}
                  style={{
                    '--border-radius': '10px',
                  }}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </div>
          <div className="w-full h-auto mt-auto flex flex-row items-center justify-end">
            <IonButton
              className=" w-[48%] h-[2.5rem] text-sm text-primary-tint font-medium"
              fill="clear"
              size="small"
              onClick={() => {
                setPriceRange({});
                setFilters({});
                setFilterCount(0);
                setFilterModalOpen(false);
              }}
              style={{
                '--border-radius': '10px',
              }}
            >
              Clear All
            </IonButton>
            <IonButton
              className="w-[48%] h-[2.5rem] font-normal"
              color="primary"
              fill="solid"
              size="small"
              onClick={() => {
                setFilterModalOpen(false);
              }}
              style={{
                '--border-radius': '10px',
              }}
            >
              Done
            </IonButton>
          </div>
        </div>
      </IonModal>
      <div className={`w-[25dvw] h-full px-4 py-2 hidden md:block`}>
        <div className="w-full h-auto flex items-center justify-between border-b-2">
          <h1 className="w-auto h-auto text-xl text-primary font-roboto font-semibold flex items-center">
            <span className="mr-2">
              <FiFilter />
            </span>
            Filters
            <div className="ml-2 w-auto h-fit px-2 text-gray-50 font-roboto font-semibold rounded-full bg-primary flex items-center justify-center text-sm">
              {filterCount}
            </div>
          </h1>
          <IonButton
            className=" h-[2rem] text-sm text-primary-tint font-medium"
            fill="clear"
            onClick={() => {
              setPriceRange({});
              setFilters({});
              setFilterCount(0);
            }}
            style={{
              '--border-radius': '10px',
            }}
          >
            Clear All
          </IonButton>
        </div>

        <div className="w-full h-auto mt-4 flex flex-col items-start">
          <div className="w-full flex h-auto items-center justify-between">
            <h2 className="w-full h-auto text-lg text-primary font-roboto font-medium">
              Categories
            </h2>
            <IonButton
              className=" text-primary-tint text-base font-normal"
              fill="clear"
              size="small"
              onClick={() => {
                setFilters({ ...filters, categories: [] });
              }}
              style={{
                '--border-radius': '10px',
              }}
            >
              Clear
            </IonButton>
          </div>

          <div className="w-full h-auto max-h-[40dvh] overflow-y-scroll mt-2 flex flex-col items-start px-2">
            {productCategories.map((item) => {
              return (
                <div
                  className="w-full h-auto mt-2 flex items-center cursor-pointer"
                  onClick={(e) => {
                    const child = (e.target as any).querySelector(
                      'ion-checkbox'
                    );
                    if (child) {
                      child.click(); // Simulates a click event on the child
                    }
                  }}
                >
                  <IonCheckbox
                    className="mx-2"
                    value={item}
                    color="primary"
                    checked={
                      filters.categories
                        ? filters.categories!.includes(item)
                        : false
                    }
                    onClick={(e) => handleCheckBoxClick(item)}
                  />
                  <p
                    onClick={(e) => {}}
                    className="w-auto h-auto text-base text-primary  font-normal"
                  >
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full h-auto mt-4 flex flex-col items-start">
          <h2 className="w-full h-auto text-lg text-primary font-roboto font-medium">
            Price
          </h2>
          <div className="w-full h-auto max-h-[40dvh] overflow-y-scroll mt-2 flex flex-col items-start px-2">
            <div className="w-full h-auto flex items-center justify-between">
              <div className="w-[46%] h-auto flex flex-col items-start">
                <p className="w-auto mb-[3px] text-primary text-sm   ml-2">
                  Min
                </p>
                <div className="w-full h-[2.5rem] flex items-center">
                  <InputCard
                    inputType="text-field"
                    name="min"
                    placeholder="Minimum Price"
                    required
                    type="number"
                    value={setPriceRange}
                    borders
                    formValues={priceRange}
                  />
                </div>
              </div>
              <div className="w-[46%] h-auto flex flex-col items-start">
                <p className="w-auto mb-[3px] text-primary text-sm ml-2">
                  Max
                </p>
                <div className="w-full h-[2.5rem] flex items-center">
                  <InputCard
                    inputType="text-field"
                    name="max"
                    placeholder="Maximum Price"
                    required
                    type="number"
                    value={setPriceRange}
                    borders
                    formValues={priceRange}
                  />
                </div>
              </div>
            </div>
            <div className="w-full h-auto flex items-center justify-center mt-2">
              <IonButton
                className="w-[49%] h-[2.5rem] text-primary font-normal"
                color="primary"
                fill="clear"
                size="small"
                disabled={priceRange.max || priceRange.min ? false : true}
                onClick={() => {
                  setPriceRange({});
                  setFilters({
                    ...filters,
                    price: null,
                  });
                }}
                style={{
                  '--border-radius': '10px',
                }}
              >
                Clear
              </IonButton>
              <IonButton
                className="w-[48%] h-[2.5rem] font-medium text-secondary-tint"
                fill="clear"
                size="small"
                onClick={() => {
                  setFilters({
                    ...filters,
                    price: {
                      min: Number(
                        priceRange.min!?.toString().replace(/,/g, '')
                      ),
                      max: Number(
                        priceRange.max!?.toString().replace(/,/g, '')
                      ),
                    },
                  });
                }}
                style={{
                  '--border-radius': '10px',
                }}
              >
                Save
              </IonButton>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100dvh] md:w-[60dvw] h-full px-2">
        <div className="w-full md:w-[40%] ml-auto h-[3rem] mb-2 md:mb-6 flex items-center">
          <InputCard
            inputType="text-field"
            name="query"
            placeholder="Search Products"
            required
            type="search"
            value={setSearch}
            borders
            clear
            formValues={search}
          />
        </div>
        <div
          id="fiter-modal-trigger"
          onClick={() => {
            setFilterModalOpen(true);
          }}
          className=" relative w-full md:w-[40%] mx-2 h-[3rem] mb-6 flex md:hidden items-center justify-between ion-activatable ripple-parent circle cursor-pointer"
        >
          <h1 className="w-auto h-auto text-base text-primary font-roboto font-semibold flex items-center">
            <span className="mr-2">
              <FiFilter />
            </span>
            Filter
            <div className="ml-2 w-auto h-auto px-2 text-gray-50 text-sm  font-semibold rounded-full bg-primary flex items-center justify-center">
              {filterCount}
            </div>
          </h1>

          <IonRippleEffect color="primary"></IonRippleEffect>
          <span className="mr-2 text-base text-primary font-roboto font-semibold">
            <IoIosArrowForward />
          </span>
        </div>
        <div className="w-full h-auto grid grid-flow-row-dense grid-cols-2 md:grid-cols-3 gap-2 py-2">
          {filtered.map((item: ProductType) => {
            return (
              <div className="h-auto">
                <ProductBlock data={{ ...item, store }} key={item.id} />
              </div>
            );
          })}
          {!filtered.length && (
            <div className="col-span-4 flex items-center justify-center h-[20dvh] text-center text-secondary font-roboto font-medium text-lg">
              No Products Found!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreProducts;
