import {
	IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
	IonPage,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import { GlobalContext } from '../../../context/provider';
import Spinner from '../../spinner';
import SellerMenu from './menu';
import SellerNav from './nav';
import { chevronUp } from 'ionicons/icons';
import { DeviceContext } from '../../../deviceType';

interface LayoutProps {
	children: React.ReactNode;
  enableScrollToToP?: boolean;
	enableRefresh?: boolean;
	handleRefresh?: (event: CustomEvent) => void;
}

const Layout = ({
	children,
  enableScrollToToP = false,
	enableRefresh = false,
	handleRefresh,
}: LayoutProps) => {
  const { userState } = useContext<any>(GlobalContext);
  const user = userState.getUser;
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const device = useContext(DeviceContext);

  const handleScroll = async (event: CustomEvent) => {
    const scrollTop = event.detail.scrollTop;
    // Show button after scrolling 200px
    setShowBackToTopButton(scrollTop > 200);
  };

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500);
  };

	return (
		<IonPage id="main-content">
			<SellerNav userDetails={user} />

			<IonContent
        ref={contentRef}
        onIonScroll={handleScroll}
        scrollEvents={true}
      >
				{/* Pull to Refresh */}
				{enableRefresh && handleRefresh && (
					<IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
				)}
				{ user?.id ? (
          <div className='w-full h-full flex'>
            {device.width >= 769 && (
              <div className="w-1/5">
                <SellerMenu />	
              </div>
            )}
						
            <div className={`${device.width >= 769 ? 'w-4/5' : 'w-full'}`}>
              { children }
            </div>
          </div>
        ) : (
          <div className='w-full h-full flex justify-center p-10'>
            <Spinner />
          </div>
        )}

        {/* Scroll to top */}
        {enableScrollToToP && showBackToTopButton && (
          <IonFab slot="fixed" style={{ bottom: '3vh', right: '0px' }}>
            <IonFabButton
              size="small"
              onClick={scrollToTop}
              style={{
                "--color": "#a94064",
                "--background": "white"
              }}
            >
              <IonIcon icon={chevronUp} />
            </IonFabButton>
          </IonFab>
        )}
			</IonContent>
		</IonPage>
	);
};

export default Layout;
