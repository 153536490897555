import React, { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonInput,
  IonTextarea,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import { trashBin, addOutline, closeCircle, trashSharp } from 'ionicons/icons';
import { Store, storeSchema } from '../../types/store';
import InputField from '../inputField';
import { TextFieldTypes } from '@ionic/core';
import classNames from 'classnames';
import { FolderEnum, uploadFile } from '../../utils/fileUpload';
import ErrorToast from '../error_toast';
import useGlobalContext from '../../hooks/useStore';
import {
  getStore,
  getStoreTypes,
  updateStore,
} from '../../context/actions/store';
import {
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_ERROR,
  GET_STORE_TYPES,
  STORE_TYPES_ERROR,
  UPDATE_STORE,
  UPDATE_STORE_ERROR,
} from '../../context/actions/types';
import Spinner from '../spinner';
import { getCurrentStore } from '../../utils/currentStore';
import { info } from 'console';
import { types } from 'util';
import SelectField from '../selectField';
import { getProductTypes } from '../../context/actions/products';

type StoreData = Omit<
  storeSchema,
  'types' | 'phoneNumber' | 'location' | 'logo'
> & {
  types: string[];
  locations: string[];
  contacts: string[];
  logo: File | string;
};

const EditStore = ({
  store,
  close,
}: {
  store: storeSchema;
  close: () => void;
}) => {
  const globalStore = useGlobalContext();
  const [storeData, setStoreData] = useState<StoreData>({
    locations: [],
    contacts: [],
    types: [],
    logo: '',
  } as StoreData);
  const [newType, setNewType] = useState<{ types: string }>({ types: '' });
  const [newContact, setNewContact] = useState<{ contacts: string }>({
    contacts: '',
  });
  const logoInputRef = useRef<any>();
  const [types, setTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newLocation, setNewLocation] = useState<{ locations: string }>({
    locations: '',
  });
  const inputs: {
    name: keyof Pick<
      StoreData,
      | 'types'
      | 'contacts'
      | 'locations'
      | 'name'
      | 'description'
      | 'email'
      | 'banner'
    >;
    array: boolean;
    placeholder: string;
    value?: string;
    textType: TextFieldTypes;
    list?: string[];
    isMultipleChoice?: boolean;
    options?: string[];
    disabled?: boolean;
    inputType: 'text-box' | 'text-field';
    col_span: { mobile: number; desktop: number };
    arrayStateHandler?: React.Dispatch<React.SetStateAction<any>>;
    newState?: { [k: string]: string };
  }[] = [
    {
      name: 'name',
      array: false,
      placeholder: 'Store Name',
      value: storeData.name,
      inputType: 'text-field',
      col_span: { mobile: 1, desktop: 1 },
      textType: 'text',
    },
    {
      name: 'email',
      array: false,
      placeholder: 'Store Email',
      value: storeData.email,
      inputType: 'text-field',
      disabled: true,
      textType: 'email',
      col_span: { mobile: 1, desktop: 1 },
    },
    {
      name: 'description',
      array: false,
      placeholder: 'Store Description',
      value: storeData.description,
      textType: 'text',
      inputType: 'text-box',
      col_span: { mobile: 1, desktop: 2 },
    },
    {
      name: 'types',
      array: true,
      placeholder: 'Store Types',
      list: storeData.types,
      textType: 'text',
      options: types,
      isMultipleChoice: true,
      inputType: 'text-field',
      arrayStateHandler: setNewType,
      newState: newType,
      col_span: { mobile: 1, desktop: 1 },
    },
    {
      name: 'contacts',
      array: true,
      placeholder: 'Store Contacts',
      list: storeData.contacts,
      textType: 'tel',
      inputType: 'text-field',
      arrayStateHandler: setNewContact,
      newState: newContact,
      col_span: { mobile: 1, desktop: 1 },
    },
    {
      name: 'locations',
      array: true,
      placeholder: 'Store Locations',
      list: storeData.locations,
      textType: 'text',
      inputType: 'text-field',
      arrayStateHandler: setNewLocation,
      newState: newLocation,
      col_span: { mobile: 1, desktop: 1 },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchTypes();
  }, []);

  useEffect(() => {
    const { type, location, phoneNumber, logo, ...rest } = store;
    setStoreData({
      ...rest,
      types: type!.split(','),
      locations: location!.split(','),
      contacts: phoneNumber!?.split(','),
      logo: logo!,
    });
  }, [store]);

  useEffect(() => {
    if (globalStore.storeTypes.storeTypes) {
      setTypes(globalStore.storeTypes.storeTypes as any[]);
    }
    if (globalStore.storeTypes.storeTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        globalStore.storeTypes.storeTypesError!?.response!?.data!?.message
          ? globalStore.storeTypes.storeTypesError!?.response!?.data!?.message
          : globalStore.storeTypes.storeTypesError!?.message
      );
      globalStore.storeTypesDispatch({
        type: STORE_TYPES_ERROR,
        payload: null,
      });
      setLoading(false);
    }
    if (globalStore.storeState!?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        globalStore.storeState?.storeError!?.response!?.data!?.message
          ? globalStore.storeState?.storeError!?.response!?.data!?.message
          : globalStore.storeState?.storeError!?.message
      );
      globalStore.storeDispatch({ type: UPDATE_STORE_ERROR, payload: null });
      setLoading(false);
    }
    if (globalStore.storeState.updateStore) {
      const { type, location, phoneNumber, logo, ...rest } =
        globalStore.storeState.updateStore;
      getStoreInfo();
      setStoreData({
        ...rest,
        types: type!.split(','),
        locations: location!.split(','),
        contacts: phoneNumber!?.split(','),
        logo: logo!,
      });
      globalStore.storeDispatch({ type: UPDATE_STORE, payload: null });
    }
  }, [globalStore.storeState, globalStore.storeTypes]);

  useEffect(() => {
    if (errorMessage) {
      setIsErrorOpen(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    setLoading(false);
  }, [types]);

  useEffect(() => {
    if (storeData.id) setLoading(false);
  }, [storeData]);

  const handleAdd = (
    field: keyof Pick<StoreData, 'types' | 'contacts' | 'locations'>,
    value: { [k: string]: string }
  ) => {
    setStoreData((prev) => ({
      ...prev,
      [field]: [...prev[field]!, Object.values(value)[0]],
    }));
  };

  const handleRemove = (
    field: keyof Pick<StoreData, 'types' | 'contacts' | 'locations'>,
    index: number
  ) => {
    setStoreData((prev) => ({
      ...prev,
      [field]: prev[field]!.filter((_, i) => i !== index),
    }));
  };

  const handleLogoUpload = async (logo: File) => {
    const uploadBanners = await uploadFile({
      files: [logo],
      folder: FolderEnum.Logos,
    });
    if (uploadBanners?.error) {
      setErrorMessage(uploadBanners.error);
      setLoading(false);
      return;
    }

    return uploadBanners;
  };
  async function getStoreInfo() {
    await getStore(Number(storeData.id))(globalStore.storeDispatch);
  }
  async function saveChanges() {
    setLoading(true);
    let logo: string = '';
    if (storeData.logo instanceof File) {
      const url = await handleLogoUpload(storeData.logo);
      logo = url[0];
    } else {
      logo = storeData.logo;
    }
    const { locations, types, contacts, ...rest } = storeData;

    const _store: storeSchema = {
      ...rest,
      location: locations.join(',')!,
      type: types.join(',')!,
      phoneNumber: contacts?.join(',')!,
      logo: logo ? logo : null,
    };
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (_store.email && !re.test(_store.email)) {
      setErrorMessage('Please Enter a Valid Email Address!');
      setLoading(false);
      return;
    }
    if (
      !_store.name ||
      !_store.phoneNumber ||
      !_store.location ||
      !_store.description
    ) {
      setErrorMessage('Please Fill All Required Fields!');
      setLoading(false);
      return;
    }

    await updateStore({ id: _store.id, store: _store })(
      globalStore.storeDispatch
    );
  }
  const fetchTypes = async () => {
    if (globalStore.storeTypes.storeTypes) {
      setTypes(globalStore.storeTypes.storeTypes as any[]);
      return;
    }
    await getStoreTypes()(globalStore.storeTypesDispatch);
  };
  const handleLogoChange = (e: any) => {
    e.preventDefault();
    setStoreData({ ...storeData, logo: e.target.files!?.[0] });
  };
  return (
    <>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
          setErrorMessage('');
        }}
      />
      {loading ? (
        <div className="size-full flex flex-row items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-full h-auto flex flex-col items-center ">
          <div className="w-full h-auto flex flex-row items-center py-2 pb-4">
            <div className="  relative aspect-square md:w-[20%] w-[50%] h-auto bg-gray-300 rounded-full mr-6 overflow-hidden border-2">
              <div className="size-full bg-gradient-to-r from-[var(--ion-color-secondary)]  via-purple-500 to-pink-500">
                <img
                  src={
                    storeData.logo! instanceof File
                      ? URL.createObjectURL(storeData.logo!)
                      : storeData?.logo
                  }
                  alt="Shop Profile"
                  className='size-full object-cover'
                />
                <input
                  className="hidden"
                  onChange={handleLogoChange}
                  type="file"
                  ref={logoInputRef}
                  accept="image/png, image/jpg, image/jpeg"
                ></input>
              </div>
              <div className="size-full absolute bg-gray-800 bg-opacity-25" />
            </div>
            <div className="flex flex-col md:flex-row md:items-center items-end">
              <IonButton
                color="secondary"
                fill="outline"
                className="mx-2"
                style={{
                  '--border-radius': '5px',
                }}
                onClick={() => {
                  logoInputRef.current.click();
                }}
              >
                UPLOAD
              </IonButton>
              <IonButton
                color="primary"
                fill="outline"
                className="mx-2 md:text-base text-sm"
                style={{
                  '--border-radius': '5px',
                }}
                onClick={() => {
                  setStoreData({
                    ...storeData,
                    logo: '',
                  });
                }}
              >
                <IonIcon slot="start" icon={trashSharp} />
                REMOVE LOGO
              </IonButton>
            </div>
          </div>

          <div className="w-full h-fit grid auto-rows-auto md:grid-cols-2 grid-cols-1 gap-x-2 ">
            {inputs.map((i, ind: number) => {
              return (
                <div
                  className={`w-full md:col-span-${i.col_span.desktop} pb-2`}
                  key={ind}
                >
                  <div
                    className={
                      'w-full h-fit font-semibold text-sm text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                    }
                  >
                    {i.placeholder}
                  </div>

                  {i.array ? (
                    i.isMultipleChoice ? (
                      <div
                        className={classNames(
                          'w-full h-[8vh] overflow-hidden py-1 ',
                          {
                            '!h-[20dvh]': i.inputType === 'text-box',
                          }
                        )}
                      >
                        <SelectField
                          value={setStoreData}
                          name={i.name}
                          options={i.options! || []}
                          multiple
                          borders
                          formValues={storeData}
                          placeholder={i.placeholder}
                          mode="md"
                        />
                      </div>
                    ) : (
                      <div className="w-full h-fit flex flex-col items-center">
                        <div className="w-full h-fit flex items-center mt-2">
                          <div
                            className={
                              'w-[80%] md:h-[8dvh] h-[7.5dvh] overflow-hidden p-1'
                            }
                          >
                            <InputField
                              required
                              name={i.name}
                              placeholder={i.placeholder}
                              type={i.textType}
                              formValues={i.newState}
                              inputType={i.inputType}
                              value={i.arrayStateHandler!}
                              // enterEvent={(e: KeyboardEvent, val) => {
                              //   handleAdd(
                              //     i.name as keyof Pick<
                              //       StoreData,
                              //       'locations' | 'contacts' | 'types'
                              //     >,
                              //     {
                              //       [Object.keys(i.newState!)[0]]: val,
                              //     }
                              //   );
                              //   i.arrayStateHandler!((e: any) => {
                              //     return {};
                              //   });
                              // }}
                              clear
                              borders
                              disabled={i.disabled}
                            />
                          </div>
                          <IonButton
                            className=" ml-2 w-[20%] h-[6.5vh] my-1"
                            fill="solid"
                            color="secondary"
                            style={{
                              '--border-radius': '10px',
                            }}
                            disabled={
                              !Object.values(i.newState!)[0].trim() ? true : false
                            }
                            onClick={() => {
                              handleAdd(
                                i.name as keyof Pick<
                                  StoreData,
                                  'locations' | 'contacts' | 'types'
                                >,
                                i.newState!
                              );
                              i.arrayStateHandler!((e: any) => {
                                return {};
                              });
                            }}
                          >
                            ADD
                          </IonButton>
                        </div>
                        <div
                          className={classNames(
                            'w-full h-fit flex flex-col items-start bg-gray-100 rounded-lg py-2 px-4 mt-2',
                            { ' !hidden ': i.list!.length === 0 }
                          )}
                        >
                          {i.list!.map((item, index) => (
                            <div
                              key={index}
                              className="w-full h-fit flex flex-row items-center justify-between pb-2 "
                            >
                              <div className="w-fit font-medium text-base text-[var(--ion-color-primary)]">
                                {item}
                              </div>
                              <IonIcon
                                icon={closeCircle}
                                color="secondary"
                                className=" cursor-pointer"
                                onClick={() =>
                                  handleRemove(
                                    i.name as keyof Pick<
                                      StoreData,
                                      'contacts' | 'locations' | 'types'
                                    >,
                                    index
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className={classNames(
                        'w-full h-[8vh] overflow-hidden py-1 ',
                        {
                          '!h-[20dvh]': i.inputType === 'text-box',
                        }
                      )}
                    >
                      <InputField
                        required
                        name={i.name}
                        label={true}
                        placeholder={i.placeholder}
                        type={i.textType}
                        inputType={i.inputType}
                        value={setStoreData}
                        formValues={storeData}
                        clear
                        borders
                        disabled={i.disabled}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="w-full h-auto py-2 flex">
            <IonButton
              fill="solid"
              color="secondary"
              style={{ '--border-radius': '10px' }}
              onClick={saveChanges}
            >
              SAVE CHANGES
            </IonButton>
            <IonButton
              fill="clear"
              color="secondary"
              onClick={() => {
                close();
              }}
            >
              CANCEL
            </IonButton>
          </div>
        </div>
      )}
    </>
  );
};

export default EditStore;
