import { IonContent } from '@ionic/react';
import Spinner from '../../../spinner';
import useGlobalContext from '../../../../context/provider';
import  { AdminMenuContent } from '../sidenav';

const AdminSlideMenu = ({action}: any) => {
  const {userState} = useGlobalContext();
  const user = userState!.getUser;
  
  return (
    <IonContent>
      {user?.id ? (
        <AdminMenuContent
          adminDetails={user}
          action={action}
          showCloseButton={true}
        />
      ) : (
        <div className='w-full h-full flex justify-center items-center'>
          <Spinner />
        </div>
      )}
    </IonContent>
  );
};

export default AdminSlideMenu;
