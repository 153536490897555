import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import classes from "./styles.module.scss";
import { isLoggedIn } from "../../../../utils/isLoggedIn";
import Menu from "../../../storeMenu/menu";
import { User } from "../../../../types/user";
import logo from "../../../../Assets/logo/logo_white_background_96.svg";
import { menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/core/components";
import { hideMenuPaths } from "../../../../utils";

const Mobile = ({
  user,
  backgroundColor = "white",
}: {
  user: User;
  backgroundColor?: "primary" | "white" | "transparent";
}) => {
  const history = useHistory();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isloggedIn = isLoggedIn();
  const [openDropDown, setOpenDropDown] = useState<{
    isOpen: boolean;
    event: Event | null;
  }>({
    isOpen: false,
    event: null,
  });

  useEffect(() => {
    const closeMenu = async () => {
      if (openMenu && hideMenuPaths.includes(location.pathname)) {
        await menuController.close('menu');
      }
    };

    closeMenu();
  }, [location.pathname, openMenu]);

  const openCntx = (e: any) => {
    setOpenDropDown({ isOpen: !openDropDown.isOpen, event: e });
  };
  return (
    <div className={classes.mobile + ' !size-full'}>
      {isloggedIn && (
        <Menu
          open={openDropDown.isOpen}
          close={openCntx}
          userDetails={user}
          openEvent={openDropDown.event!}
        />
      )}
      <IonRow className={classes.holder + ' !size-full'}>
        <IonCol size="11"
          className={classes['logo-col']}
        >
          <div
            onClick={() => {
              history.push("/");
            }}
            className={classes['logo-wrapper']}
          >
            <IonIcon src={logo} className="!text-[3rem]"></IonIcon>
            <span
              className={classes.span1}
              style={{
                color:
                  backgroundColor === "white"
                    ? "var(--ion-color-primary)"
                    : "var(--ion-color-primary-contrast)",
              }}
            >
              Bazaar
            </span>{" "}
            <span className={classes.span2}>Cheap</span>
          </div>
        </IonCol>

        {/* <IonCol size="2">
          {isloggedIn && (
            <div className={classes.chip}>
              <StoreMenu
                userDetails={user}
                open={openDropDown.isOpen}
                openCntx={openCntx}
              />
            </div>
          )}
        </IonCol> */}
        <IonCol
          size="1"
          className={classes['menu-col']}
        >
          <IonIcon
            icon={menuOutline}
            color="primary"
            onClick={async () => {
              await menuController.open("menu");
              setOpenMenu(await menuController.isOpen("menu"));
            }}
            ></IonIcon>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default Mobile;
