import React, { useContext } from 'react';
import {
	IonPage,
	IonContent,
} from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import Spinner from '../../spinner';
import AdminNav from './nav';
import AdminMenu from './sidenav';
import useGlobalContext from '../../../context/provider';

const Layout = ({ children }: any) => {
	const device = useContext(DeviceContext);
	const { userState } = useGlobalContext();
	const user = userState!.getUser;

	return (
		<IonPage id="main-content">
			{/* nav/ header */}
			<AdminNav adminDetails={user} />

			<IonContent>
				{user?.id ? (
					<div className='w-full h-full flex'>
						{device.width >= 769 && (
							<div className="w-1/5">
								<AdminMenu adminDetails={user} />	
							</div>
						)}
						
						<div className={`${device.width >= 769 ? 'w-4/5' : 'w-full'}`}>
							{children}
						</div>
					</div>
				) : (
					<div className='w-full h-full flex justify-center items-center'>
						<Spinner />
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Layout;
