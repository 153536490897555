import React from 'react';

const StoreLogoPlaceholder = ({ text }: { text: string }) => {
  const randomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  const gradientId = 'random-gradient';
  return (
    <svg width="100%" height="100%">
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={randomColor()} />
          <stop offset="25%" stopColor={randomColor()} />
          <stop offset="50%" stopColor={randomColor()} />
          <stop offset="75%" stopColor={randomColor()} />
          <stop offset="100%" stopColor={randomColor()} />
        </linearGradient>
      </defs>
      <circle cx="50%" cy="50%" r="50%" fill={`url(#${gradientId})`} />
      <text
        x="50%"
        y="50%"
        alignmentBaseline="central"
        textAnchor="middle"
        fontFamily="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"
        fontSize="18"
        fontWeight="bold"
        fill="#ffff"
      >
        {text}
      </text>
    </svg>
  );
};

export default StoreLogoPlaceholder;
