import { Category, Offer } from '../types';

export const cutString = (str: string, length: number) => {
  return str.slice(0, length);
};

export const addToLocalStorage = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  localStorage.setItem(key, value);
  window.dispatchEvent(new Event('storage'));
  return;
};
export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  return;
};

export const toTitleCase = (str: string) => {
  return str?.toLowerCase()?.replace(/(?:^|\s)\w/g, (match) => {
    return match?.toUpperCase();
  });
};

export const mockchip = [
  { name: 'tellus', route: `/search/`, category: 3 },
  { name: 'ipsum', route: `/search/`, category: 5 },
  { name: 'dolor', route: `/search/`, category: 1 },
  { name: 'amet consectetu', route: `/search/`, category: 1 },
  { name: 'adipisicing', route: `/search/`, category: 4 },
  { name: 'Totam, saepe', route: `/search/`, category: 8 },
  { name: 'sit amet', route: `/search/`, category: 14 },
  { name: ' Lorem ', route: `/search/`, category: 1 },
];

export const Categories: Category[] = [
  {
    id: 34,
    name: 'Video & Television',
    img: 'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    children: [
      {
        id: 23,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 234,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 5986,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 223,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
    ],
  },
  {
    id: 45,
    name: 'Phone & Tablet',
    img: 'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    children: [
      {
        id: 23,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 234,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 5986,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 223,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
    ],
  },
  {
    id: 100,
    name: 'Furniture & Decor',
    img: 'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    children: [
      {
        id: 23,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 234,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 5986,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 223,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
    ],
  },
  {
    id: 234,
    name: 'KitchenWare',
    img: 'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    children: [
      {
        id: 23,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 234,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 5986,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 223,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
    ],
  },
  {
    id: 34,
    name: 'Video & Television',
    img: 'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    children: [
      {
        id: 23,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 234,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 5986,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
      {
        id: 223,
        name: 'Samsung 55 AU8000 4K Crystal UHD HDR Smart TV - 3 HDMI (2022)',
        image:
          'https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      },
    ],
  },
];

export const Offers: Offer[] = [
  {
    title: 'Juice - Grape, White',
    description:
      'ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra',
    seller: 'Heathcote, Carroll and Lebsack',
    currentPrice: 10959,
    originalPrice: 10068,
    sellerId: 228,
    headImageURL: 'http://dummyimage.com/185x100.png/dddddd/000000',
    OfferId: 536,
    Category: 92,
  },
  {
    title: 'Pasta - Penne Primavera, Single',
    description:
      'pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae mauris viverra diam vitae quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam sit amet diam in magna',
    seller: 'Paucek, Deckow and White',
    currentPrice: 8437,
    originalPrice: 10456,
    sellerId: 373,
    headImageURL: 'http://dummyimage.com/187x100.png/5fa2dd/ffffff',
    OfferId: 450,
    Category: 45,
  },
  {
    title: 'Absolut Citron',
    description:
      'posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor sollicitudin mi sit amet',
    seller: 'Reilly, Sanford and Parisian',
    currentPrice: 33870,
    originalPrice: 36280,
    sellerId: 278,
    headImageURL: 'http://dummyimage.com/104x100.png/cc0000/ffffff',
    OfferId: 856,
    Category: 68,
  },
  {
    title: 'Cheese - Provolone',
    description:
      'vehicula condimentum curabitur in libero ut massa volutpat convallis morbi odio odio elementum eu interdum eu tincidunt in leo maecenas pulvinar lobortis est phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu mi nulla ac enim in tempor',
    seller: 'Casper, Medhurst and Wyman',
    currentPrice: 23193,
    originalPrice: 37871,
    sellerId: 228,
    headImageURL: 'http://dummyimage.com/177x100.png/5fa2dd/ffffff',
    OfferId: 222,
    Category: 9,
  },
  {
    title: 'Cake Slab',
    description:
      'justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus metus arcu',
    seller: 'Hauck-Zemlak',
    currentPrice: 20187,
    originalPrice: 10713,
    sellerId: 968,
    headImageURL: 'http://dummyimage.com/222x100.png/dddddd/000000',
    OfferId: 892,
    Category: 16,
  },
  {
    title: 'Soup - Boston Clam Chowder',
    description:
      'purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas ut massa quis augue luctus tincidunt nulla mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in hac habitasse platea',
    seller: 'Roberts, Monahan and Donnelly',
    currentPrice: 36599,
    originalPrice: 14903,
    sellerId: 260,
    headImageURL: 'http://dummyimage.com/226x100.png/cc0000/ffffff',
    OfferId: 205,
    Category: 57,
  },
  {
    title: 'Gatorade - Orange',
    description:
      'viverra dapibus nulla suscipit ligula in lacus curabitur at ipsum ac tellus semper interdum mauris ullamcorper purus sit amet',
    seller: 'Hermann, Huels and Zboncak',
    currentPrice: 48539,
    originalPrice: 10077,
    sellerId: 279,
    headImageURL: 'http://dummyimage.com/217x100.png/5fa2dd/ffffff',
    OfferId: 818,
    Category: 94,
  },
  {
    title: 'Sugar Thermometer',
    description:
      'vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi',
    seller: 'Ortiz-VonRueden',
    currentPrice: 14076,
    originalPrice: 26236,
    sellerId: 428,
    headImageURL: 'http://dummyimage.com/143x100.png/5fa2dd/ffffff',
    OfferId: 930,
    Category: 64,
  },
  {
    title: 'Chinese Lemon Pork',
    description:
      'platea dictumst morbi vestibulum velit id pretium iaculis diam erat fermentum justo nec condimentum neque sapien placerat ante nulla justo aliquam quis turpis eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean',
    seller: 'Will-Monahan',
    currentPrice: 40730,
    originalPrice: 22289,
    sellerId: 527,
    headImageURL: 'http://dummyimage.com/175x100.png/dddddd/000000',
    OfferId: 803,
    Category: 63,
  },
  {
    title: 'Bread Base - Gold Formel',
    description:
      'neque sapien placerat ante nulla justo aliquam quis turpis eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris eget massa tempor convallis nulla neque',
    seller: 'Bernier LLC',
    currentPrice: 13825,
    originalPrice: 37614,
    sellerId: 981,
    headImageURL: 'http://dummyimage.com/231x100.png/5fa2dd/ffffff',
    OfferId: 372,
    Category: 81,
  },
  {
    title: 'Cheese - Cheddar, Old White',
    description:
      'turpis donec posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed',
    seller: "Flatley-O'Kon",
    currentPrice: 17743,
    originalPrice: 39917,
    sellerId: 208,
    headImageURL: 'http://dummyimage.com/202x100.png/5fa2dd/ffffff',
    OfferId: 136,
    Category: 73,
  },
  {
    title: 'Vodka - Hot, Lnferno',
    description:
      'ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur',
    seller: 'Cronin Group',
    currentPrice: 19922,
    originalPrice: 23766,
    sellerId: 55,
    headImageURL: 'http://dummyimage.com/101x100.png/ff4444/ffffff',
    OfferId: 315,
    Category: 52,
  },
  {
    title: 'Fireball Whisky',
    description:
      'libero non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla sed vel enim sit amet nunc viverra dapibus nulla suscipit ligula in lacus curabitur',
    seller: 'Russel-Grant',
    currentPrice: 32287,
    originalPrice: 37122,
    sellerId: 576,
    headImageURL: 'http://dummyimage.com/138x100.png/5fa2dd/ffffff',
    OfferId: 125,
    Category: 84,
  },
  {
    title: 'Longos - Chicken Caeser Salad',
    description:
      'nulla suspendisse potenti cras in purus eu magna vulputate luctus cum sociis natoque penatibus et magnis dis',
    seller: 'Nikolaus Group',
    currentPrice: 35404,
    originalPrice: 34955,
    sellerId: 657,
    headImageURL: 'http://dummyimage.com/158x100.png/cc0000/ffffff',
    OfferId: 123,
    Category: 20,
  },
  {
    title: 'Radish',
    description:
      'fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id turpis integer aliquet massa id lobortis convallis tortor risus dapibus augue vel accumsan',
    seller: 'Cruickshank-Lemke',
    currentPrice: 4938,
    originalPrice: 37163,
    sellerId: 48,
    headImageURL: 'http://dummyimage.com/221x100.png/5fa2dd/ffffff',
    OfferId: 806,
    Category: 39,
  },
  {
    title: 'Wakami Seaweed',
    description:
      'a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque volutpat',
    seller: 'Fritsch Inc',
    currentPrice: 47058,
    originalPrice: 24001,
    sellerId: 142,
    headImageURL: 'http://dummyimage.com/113x100.png/dddddd/000000',
    OfferId: 169,
    Category: 29,
  },
  {
    title: 'Puree - Kiwi',
    description:
      'in congue etiam justo etiam pretium iaculis justo in hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at',
    seller: 'Gorczany, Wyman and Carter',
    currentPrice: 21517,
    originalPrice: 35549,
    sellerId: 599,
    headImageURL: 'http://dummyimage.com/116x100.png/ff4444/ffffff',
    OfferId: 370,
    Category: 90,
  },
  {
    title: 'Cheese - Shred Cheddar / Mozza',
    description:
      'nisi venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed augue aliquam erat volutpat in congue etiam justo etiam pretium iaculis justo in hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut erat',
    seller: 'Jones-Batz',
    currentPrice: 8532,
    originalPrice: 22510,
    sellerId: 756,
    headImageURL: 'http://dummyimage.com/204x100.png/5fa2dd/ffffff',
    OfferId: 61,
    Category: 76,
  },
  {
    title: 'Sugar - Splenda Sweetener',
    description:
      'ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget',
    seller: 'Bernier, Ruecker and Abernathy',
    currentPrice: 9606,
    originalPrice: 13680,
    sellerId: 700,
    headImageURL: 'http://dummyimage.com/210x100.png/cc0000/ffffff',
    OfferId: 844,
    Category: 24,
  },
  {
    title: 'Truffle Cups - White Paper',
    description:
      'morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna',
    seller: 'Kihn Inc',
    currentPrice: 18693,
    originalPrice: 10826,
    sellerId: 577,
    headImageURL: 'http://dummyimage.com/129x100.png/ff4444/ffffff',
    OfferId: 209,
    Category: 69,
  },
  {
    title: 'Beef - Flank Steak',
    description:
      'pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna',
    seller: 'Howell Group',
    currentPrice: 20261,
    originalPrice: 34939,
    sellerId: 815,
    headImageURL: 'http://dummyimage.com/213x100.png/ff4444/ffffff',
    OfferId: 874,
    Category: 91,
  },
  {
    title: 'Potatoes - Yukon Gold 5 Oz',
    description:
      'est phasellus sit amet erat nulla tempus vivamus in felis eu sapien',
    seller: 'Bruen Group',
    currentPrice: 17718,
    originalPrice: 22178,
    sellerId: 626,
    headImageURL: 'http://dummyimage.com/216x100.png/5fa2dd/ffffff',
    OfferId: 800,
    Category: 36,
  },
  {
    title: 'Beef - Top Sirloin',
    description:
      'luctus rutrum nulla tellus in sagittis dui vel nisl duis ac nibh fusce lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas ut massa quis augue luctus tincidunt nulla',
    seller: 'Bergstrom-Wilderman',
    currentPrice: 2764,
    originalPrice: 34104,
    sellerId: 372,
    headImageURL: 'http://dummyimage.com/203x100.png/5fa2dd/ffffff',
    OfferId: 502,
    Category: 71,
  },
  {
    title: 'Quail - Whole, Boneless',
    description:
      'duis aliquam convallis nunc proin at turpis a pede posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor sollicitudin mi',
    seller: 'Brown, Ferry and Brekke',
    currentPrice: 39199,
    originalPrice: 19258,
    sellerId: 718,
    headImageURL: 'http://dummyimage.com/141x100.png/5fa2dd/ffffff',
    OfferId: 945,
    Category: 20,
  },
  {
    title: 'Salmon - Atlantic, Skin On',
    description:
      'curabitur at ipsum ac tellus semper interdum mauris ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel dapibus at',
    seller: 'Baumbach, Wilkinson and Ankunding',
    currentPrice: 23253,
    originalPrice: 35210,
    sellerId: 106,
    headImageURL: 'http://dummyimage.com/161x100.png/cc0000/ffffff',
    OfferId: 434,
    Category: 20,
  },
  {
    title: 'Beef Flat Iron Steak',
    description:
      'est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae mauris viverra diam vitae quam suspendisse',
    seller: 'Sauer-Bogan',
    currentPrice: 18729,
    originalPrice: 13369,
    sellerId: 915,
    headImageURL: 'http://dummyimage.com/206x100.png/5fa2dd/ffffff',
    OfferId: 300,
    Category: 61,
  },
  {
    title: 'Sole - Iqf',
    description:
      'pretium iaculis diam erat fermentum justo nec condimentum neque sapien placerat ante nulla justo aliquam quis turpis eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean',
    seller: 'Kunze, Shanahan and Bosco',
    currentPrice: 48792,
    originalPrice: 29494,
    sellerId: 729,
    headImageURL: 'http://dummyimage.com/228x100.png/5fa2dd/ffffff',
    OfferId: 578,
    Category: 46,
  },
  {
    title: 'Cheese - Cheddar, Old White',
    description:
      'in sagittis dui vel nisl duis ac nibh fusce lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas',
    seller: 'Boehm-Hirthe',
    currentPrice: 49634,
    originalPrice: 36472,
    sellerId: 884,
    headImageURL: 'http://dummyimage.com/120x100.png/dddddd/000000',
    OfferId: 188,
    Category: 67,
  },
  {
    title: 'Wine - Riesling Alsace Ac 2001',
    description:
      'at ipsum ac tellus semper interdum mauris ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel dapibus at diam',
    seller: 'Heidenreich LLC',
    currentPrice: 27078,
    originalPrice: 13649,
    sellerId: 14,
    headImageURL: 'http://dummyimage.com/146x100.png/cc0000/ffffff',
    OfferId: 281,
    Category: 30,
  },
  {
    title: 'Stock - Beef, White',
    description:
      'turpis nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh',
    seller: 'Beahan-Hane',
    currentPrice: 7783,
    originalPrice: 11262,
    sellerId: 888,
    headImageURL: 'http://dummyimage.com/247x100.png/dddddd/000000',
    OfferId: 718,
    Category: 96,
  },
  {
    title: 'Tomatoes - Yellow Hot House',
    description:
      'viverra diam vitae quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non',
    seller: 'Ritchie, Barton and Kirlin',
    currentPrice: 20141,
    originalPrice: 39845,
    sellerId: 796,
    headImageURL: 'http://dummyimage.com/193x100.png/dddddd/000000',
    OfferId: 875,
    Category: 18,
  },
  {
    title: 'Tray - Foam, Square 4 - S',
    description:
      'amet consectetuer adipiscing elit proin risus praesent lectus vestibulum quam sapien varius ut blandit non interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi',
    seller: 'Reichel and Sons',
    currentPrice: 49133,
    originalPrice: 28335,
    sellerId: 118,
    headImageURL: 'http://dummyimage.com/237x100.png/cc0000/ffffff',
    OfferId: 154,
    Category: 39,
  },
  {
    title: 'Veal - Ground',
    description:
      'gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien in',
    seller: 'Wiegand, Simonis and Jaskolski',
    currentPrice: 2440,
    originalPrice: 36506,
    sellerId: 607,
    headImageURL: 'http://dummyimage.com/147x100.png/cc0000/ffffff',
    OfferId: 729,
    Category: 37,
  },
  {
    title: 'Soup - Knorr, Chicken Noodle',
    description:
      'augue vestibulum rutrum rutrum neque aenean auctor gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing',
    seller: 'Cruickshank Inc',
    currentPrice: 2263,
    originalPrice: 22460,
    sellerId: 603,
    headImageURL: 'http://dummyimage.com/142x100.png/cc0000/ffffff',
    OfferId: 366,
    Category: 89,
  },
  {
    title: 'Bread - Roll, Whole Wheat',
    description:
      'vestibulum ac est lacinia nisi venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed augue aliquam erat',
    seller: 'Bechtelar-Koch',
    currentPrice: 24483,
    originalPrice: 12980,
    sellerId: 526,
    headImageURL: 'http://dummyimage.com/212x100.png/cc0000/ffffff',
    OfferId: 364,
    Category: 38,
  },
  {
    title: 'Pop - Club Soda Can',
    description:
      'sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi',
    seller: 'Halvorson, Jaskolski and Rutherford',
    currentPrice: 26926,
    originalPrice: 34689,
    sellerId: 659,
    headImageURL: 'http://dummyimage.com/154x100.png/5fa2dd/ffffff',
    OfferId: 743,
    Category: 73,
  },
  {
    title: 'Island Oasis - Pina Colada',
    description:
      'purus eu magna vulputate luctus cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus vivamus vestibulum sagittis sapien cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus etiam vel augue vestibulum rutrum rutrum neque aenean auctor gravida sem praesent id massa',
    seller: 'Hansen and Sons',
    currentPrice: 4464,
    originalPrice: 24553,
    sellerId: 469,
    headImageURL: 'http://dummyimage.com/134x100.png/cc0000/ffffff',
    OfferId: 445,
    Category: 73,
  },
  {
    title: 'Flour - Bread',
    description:
      'elementum eu interdum eu tincidunt in leo maecenas pulvinar lobortis est phasellus sit amet erat nulla tempus vivamus',
    seller: 'Lynch Inc',
    currentPrice: 26543,
    originalPrice: 28969,
    sellerId: 825,
    headImageURL: 'http://dummyimage.com/125x100.png/cc0000/ffffff',
    OfferId: 933,
    Category: 90,
  },
  {
    title: 'Nut - Hazelnut, Whole',
    description:
      'donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat',
    seller: 'Friesen-Dickinson',
    currentPrice: 35625,
    originalPrice: 16689,
    sellerId: 906,
    headImageURL: 'http://dummyimage.com/168x100.png/cc0000/ffffff',
    OfferId: 15,
    Category: 84,
  },
  {
    title: 'Beets',
    description:
      'quisque porta volutpat erat quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus phasellus in felis donec semper sapien a libero nam dui proin leo odio porttitor id consequat in consequat ut nulla sed accumsan felis ut at dolor quis odio consequat varius integer ac',
    seller: 'Shields-Muller',
    currentPrice: 16731,
    originalPrice: 19700,
    sellerId: 560,
    headImageURL: 'http://dummyimage.com/245x100.png/5fa2dd/ffffff',
    OfferId: 431,
    Category: 97,
  },
  {
    title: 'Table Cloth 54x72 Colour',
    description:
      'molestie hendrerit at vulputate vitae nisl aenean lectus pellentesque eget nunc donec quis orci eget orci vehicula condimentum curabitur in',
    seller: 'Parisian Inc',
    currentPrice: 14107,
    originalPrice: 25479,
    sellerId: 80,
    headImageURL: 'http://dummyimage.com/192x100.png/5fa2dd/ffffff',
    OfferId: 786,
    Category: 20,
  },
  {
    title: 'Wine - Two Oceans Cabernet',
    description:
      'eleifend luctus ultricies eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec',
    seller: 'Balistreri-Kling',
    currentPrice: 19622,
    originalPrice: 10359,
    sellerId: 710,
    headImageURL: 'http://dummyimage.com/184x100.png/dddddd/000000',
    OfferId: 733,
    Category: 72,
  },
  {
    title: 'Bread - Hamburger Buns',
    description:
      'leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede',
    seller: 'Douglas-Spinka',
    currentPrice: 10952,
    originalPrice: 39460,
    sellerId: 941,
    headImageURL: 'http://dummyimage.com/105x100.png/5fa2dd/ffffff',
    OfferId: 643,
    Category: 53,
  },
  {
    title: 'Food Colouring - Pink',
    description:
      'dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae nulla',
    seller: 'Rolfson, Orn and Ruecker',
    currentPrice: 6322,
    originalPrice: 16454,
    sellerId: 608,
    headImageURL: 'http://dummyimage.com/113x100.png/ff4444/ffffff',
    OfferId: 324,
    Category: 62,
  },
  {
    title: 'Grapes - Red',
    description:
      'nam ultrices libero non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla',
    seller: 'Cruickshank LLC',
    currentPrice: 7085,
    originalPrice: 31714,
    sellerId: 411,
    headImageURL: 'http://dummyimage.com/117x100.png/cc0000/ffffff',
    OfferId: 310,
    Category: 93,
  },
  {
    title: 'Gherkin - Sour',
    description:
      'viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus',
    seller: 'Robel Group',
    currentPrice: 49109,
    originalPrice: 15593,
    sellerId: 944,
    headImageURL: 'http://dummyimage.com/157x100.png/dddddd/000000',
    OfferId: 47,
    Category: 49,
  },
  {
    title: 'Seabream Whole Farmed',
    description:
      'ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio',
    seller: 'Kohler Inc',
    currentPrice: 27090,
    originalPrice: 32903,
    sellerId: 264,
    headImageURL: 'http://dummyimage.com/173x100.png/cc0000/ffffff',
    OfferId: 894,
    Category: 4,
  },
  {
    title: 'Fond - Neutral',
    description:
      'nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam',
    seller: 'Schoen, Waters and Balistreri',
    currentPrice: 34659,
    originalPrice: 30449,
    sellerId: 558,
    headImageURL: 'http://dummyimage.com/179x100.png/dddddd/000000',
    OfferId: 626,
    Category: 20,
  },
  {
    title: 'Lumpfish Black',
    description:
      'sed ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies eu',
    seller: 'Lockman, Von and Rippin',
    currentPrice: 8396,
    originalPrice: 25094,
    sellerId: 869,
    headImageURL: 'http://dummyimage.com/231x100.png/dddddd/000000',
    OfferId: 453,
    Category: 20,
  },
  {
    title: 'Pasta - Lasagna, Dry',
    description:
      'tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet',
    seller: 'Effertz-Stehr',
    currentPrice: 44526,
    originalPrice: 12892,
    sellerId: 104,
    headImageURL: 'http://dummyimage.com/233x100.png/ff4444/ffffff',
    OfferId: 124,
    Category: 18,
  },
  {
    title: 'Mace',
    description:
      'ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor sollicitudin mi sit amet lobortis sapien sapien non mi integer ac neque duis bibendum morbi non quam nec dui luctus rutrum nulla tellus in sagittis dui vel nisl duis ac nibh',
    seller: 'Hane, Kilback and Raynor',
    currentPrice: 44881,
    originalPrice: 23178,
    sellerId: 506,
    headImageURL: 'http://dummyimage.com/198x100.png/dddddd/000000',
    OfferId: 282,
    Category: 88,
  },
  {
    title: 'Beef - Tenderloin Tails',
    description:
      'ultricies eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est',
    seller: 'Price, Reinger and Senger',
    currentPrice: 27487,
    originalPrice: 11800,
    sellerId: 262,
    headImageURL: 'http://dummyimage.com/114x100.png/5fa2dd/ffffff',
    OfferId: 987,
    Category: 37,
  },
  {
    title: 'Beans - Navy, Dry',
    description:
      'suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis tristique fusce',
    seller: 'McKenzie, Hyatt and Lakin',
    currentPrice: 28527,
    originalPrice: 38837,
    sellerId: 180,
    headImageURL: 'http://dummyimage.com/206x100.png/dddddd/000000',
    OfferId: 941,
    Category: 43,
  },
  {
    title: 'Muffin Mix - Carrot',
    description:
      'a odio in hac habitasse platea dictumst maecenas ut massa quis augue luctus tincidunt nulla mollis molestie',
    seller: 'Hintz and Sons',
    currentPrice: 46027,
    originalPrice: 27313,
    sellerId: 60,
    headImageURL: 'http://dummyimage.com/181x100.png/cc0000/ffffff',
    OfferId: 326,
    Category: 78,
  },
  {
    title: 'Duck - Fat',
    description:
      'ipsum aliquam non mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede',
    seller: 'Rippin-Ryan',
    currentPrice: 1365,
    originalPrice: 13629,
    sellerId: 156,
    headImageURL: 'http://dummyimage.com/149x100.png/ff4444/ffffff',
    OfferId: 619,
    Category: 26,
  },
  {
    title: 'Shrimp - Baby, Cold Water',
    description:
      'vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia',
    seller: 'Swift and Sons',
    currentPrice: 16399,
    originalPrice: 35035,
    sellerId: 899,
    headImageURL: 'http://dummyimage.com/109x100.png/5fa2dd/ffffff',
    OfferId: 233,
    Category: 74,
  },
  {
    title: 'Soup - Knorr, Chicken Gumbo',
    description:
      'facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus',
    seller: 'Heidenreich-Murray',
    currentPrice: 13389,
    originalPrice: 20466,
    sellerId: 909,
    headImageURL: 'http://dummyimage.com/238x100.png/dddddd/000000',
    OfferId: 413,
    Category: 79,
  },
  {
    title: 'Turkey - Breast, Boneless Sk On',
    description:
      'quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris eget massa tempor convallis nulla',
    seller: 'Bins-Rempel',
    currentPrice: 17330,
    originalPrice: 35089,
    sellerId: 483,
    headImageURL: 'http://dummyimage.com/246x100.png/ff4444/ffffff',
    OfferId: 557,
    Category: 31,
  },
  {
    title: 'Pie Filling - Pumpkin',
    description:
      'mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in',
    seller: 'Adams, McDermott and Fisher',
    currentPrice: 31627,
    originalPrice: 24275,
    sellerId: 835,
    headImageURL: 'http://dummyimage.com/158x100.png/ff4444/ffffff',
    OfferId: 12,
    Category: 83,
  },
  {
    title: 'Soup Knorr Chili With Beans',
    description:
      'ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id turpis integer aliquet',
    seller: 'Welch Group',
    currentPrice: 49853,
    originalPrice: 12395,
    sellerId: 510,
    headImageURL: 'http://dummyimage.com/220x100.png/5fa2dd/ffffff',
    OfferId: 551,
    Category: 47,
  },
  {
    title: 'Silicone Paper 16.5x24',
    description:
      'feugiat non pretium quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas ut massa quis augue luctus tincidunt nulla mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in hac habitasse platea dictumst aliquam augue quam',
    seller: 'Yost Inc',
    currentPrice: 34568,
    originalPrice: 16582,
    sellerId: 690,
    headImageURL: 'http://dummyimage.com/122x100.png/cc0000/ffffff',
    OfferId: 985,
    Category: 94,
  },
  {
    title: 'Wine - Acient Coast Caberne',
    description:
      'diam in magna bibendum imperdiet nullam orci pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl',
    seller: 'Breitenberg Group',
    currentPrice: 40558,
    originalPrice: 16249,
    sellerId: 288,
    headImageURL: 'http://dummyimage.com/196x100.png/ff4444/ffffff',
    OfferId: 372,
    Category: 61,
  },
  {
    title: 'Ham - Virginia',
    description:
      'tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus',
    seller: 'Frami, Williamson and Ledner',
    currentPrice: 20981,
    originalPrice: 17081,
    sellerId: 334,
    headImageURL: 'http://dummyimage.com/144x100.png/cc0000/ffffff',
    OfferId: 417,
    Category: 44,
  },
  {
    title: 'Wine - Blue Nun Qualitatswein',
    description:
      'velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec pharetra magna vestibulum',
    seller: 'Ruecker, Koch and Johns',
    currentPrice: 14963,
    originalPrice: 38819,
    sellerId: 838,
    headImageURL: 'http://dummyimage.com/248x100.png/cc0000/ffffff',
    OfferId: 116,
    Category: 76,
  },
  {
    title: 'Ice Cream Bar - Oreo Cone',
    description:
      'pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique',
    seller: 'Bogisich, Lueilwitz and Waters',
    currentPrice: 14350,
    originalPrice: 17426,
    sellerId: 193,
    headImageURL: 'http://dummyimage.com/173x100.png/cc0000/ffffff',
    OfferId: 283,
    Category: 58,
  },
  {
    title: 'Truffle Shells - White Chocolate',
    description:
      'consequat varius integer ac leo pellentesque ultrices mattis odio donec vitae nisi nam ultrices libero non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla sed vel enim sit amet nunc viverra dapibus nulla',
    seller: 'Considine, Fritsch and Kautzer',
    currentPrice: 24338,
    originalPrice: 36940,
    sellerId: 427,
    headImageURL: 'http://dummyimage.com/189x100.png/cc0000/ffffff',
    OfferId: 535,
    Category: 90,
  },
  {
    title: 'Nestea - Ice Tea, Diet',
    description:
      'laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel sem sed sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede libero quis orci nullam molestie nibh in lectus pellentesque at nulla suspendisse potenti',
    seller: 'Sanford, Grant and Jenkins',
    currentPrice: 3164,
    originalPrice: 13124,
    sellerId: 738,
    headImageURL: 'http://dummyimage.com/241x100.png/dddddd/000000',
    OfferId: 651,
    Category: 33,
  },
  {
    title: 'Iced Tea Concentrate',
    description:
      'in hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque porta',
    seller: 'Wiza Inc',
    currentPrice: 14208,
    originalPrice: 15586,
    sellerId: 430,
    headImageURL: 'http://dummyimage.com/165x100.png/cc0000/ffffff',
    OfferId: 233,
    Category: 43,
  },
  {
    title: 'Chicken - White Meat With Tender',
    description:
      'id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum',
    seller: 'Brakus Inc',
    currentPrice: 27749,
    originalPrice: 28732,
    sellerId: 233,
    headImageURL: 'http://dummyimage.com/136x100.png/cc0000/ffffff',
    OfferId: 626,
    Category: 80,
  },
  {
    title: 'Celery Root',
    description:
      'gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor',
    seller: 'Mann-Will',
    currentPrice: 31906,
    originalPrice: 23103,
    sellerId: 498,
    headImageURL: 'http://dummyimage.com/180x100.png/ff4444/ffffff',
    OfferId: 29,
    Category: 46,
  },
  {
    title: 'Dasheen',
    description:
      'neque libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio justo sollicitudin',
    seller: 'Spinka LLC',
    currentPrice: 30248,
    originalPrice: 29064,
    sellerId: 910,
    headImageURL: 'http://dummyimage.com/173x100.png/cc0000/ffffff',
    OfferId: 814,
    Category: 97,
  },
  {
    title: 'Corn Shoots',
    description:
      'morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue',
    seller: 'Hane Group',
    currentPrice: 49650,
    originalPrice: 37335,
    sellerId: 276,
    headImageURL: 'http://dummyimage.com/117x100.png/5fa2dd/ffffff',
    OfferId: 19,
    Category: 100,
  },
  {
    title: 'Tea - Decaf 1 Cup',
    description:
      'orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio justo sollicitudin ut suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl ut volutpat sapien',
    seller: 'Lindgren LLC',
    currentPrice: 37041,
    originalPrice: 38221,
    sellerId: 701,
    headImageURL: 'http://dummyimage.com/233x100.png/5fa2dd/ffffff',
    OfferId: 164,
    Category: 6,
  },
  {
    title: 'Veal - Osso Bucco',
    description:
      'in felis donec semper sapien a libero nam dui proin leo odio porttitor id consequat in consequat ut nulla sed accumsan felis ut at dolor quis odio consequat varius integer ac leo pellentesque ultrices mattis odio',
    seller: 'Shanahan Group',
    currentPrice: 7102,
    originalPrice: 37840,
    sellerId: 119,
    headImageURL: 'http://dummyimage.com/100x100.png/5fa2dd/ffffff',
    OfferId: 840,
    Category: 60,
  },
  {
    title: 'Wine - Lou Black Shiraz',
    description:
      'magnis dis parturient montes nascetur ridiculus mus vivamus vestibulum sagittis sapien cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus etiam',
    seller: 'Mertz-Stoltenberg',
    currentPrice: 9783,
    originalPrice: 11069,
    sellerId: 948,
    headImageURL: 'http://dummyimage.com/175x100.png/cc0000/ffffff',
    OfferId: 172,
    Category: 86,
  },
  {
    title: 'Crush - Grape, 355 Ml',
    description:
      'vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
    seller: 'Klein, Kirlin and Volkman',
    currentPrice: 39977,
    originalPrice: 34582,
    sellerId: 757,
    headImageURL: 'http://dummyimage.com/199x100.png/dddddd/000000',
    OfferId: 387,
    Category: 81,
  },
  {
    title: 'Bread Sour Rolls',
    description:
      'eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus phasellus in felis donec semper sapien a libero nam dui proin leo odio porttitor id',
    seller: 'Koss-Russel',
    currentPrice: 49946,
    originalPrice: 35775,
    sellerId: 897,
    headImageURL: 'http://dummyimage.com/172x100.png/5fa2dd/ffffff',
    OfferId: 694,
    Category: 88,
  },
  {
    title: 'Beef - Sushi Flat Iron Steak',
    description:
      'nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus phasellus in felis donec semper sapien a libero nam',
    seller: 'Carroll LLC',
    currentPrice: 14661,
    originalPrice: 28739,
    sellerId: 612,
    headImageURL: 'http://dummyimage.com/158x100.png/dddddd/000000',
    OfferId: 258,
    Category: 54,
  },
  {
    title: 'Initation Crab Meat',
    description:
      'imperdiet nullam orci pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel sem sed sagittis nam congue risus',
    seller: 'Ledner, Berge and Hyatt',
    currentPrice: 28037,
    originalPrice: 19054,
    sellerId: 725,
    headImageURL: 'http://dummyimage.com/205x100.png/ff4444/ffffff',
    OfferId: 203,
    Category: 4,
  },
  {
    title: 'Beef Cheek Fresh',
    description:
      'ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed',
    seller: 'Wolf, Willms and Nienow',
    currentPrice: 28182,
    originalPrice: 36853,
    sellerId: 187,
    headImageURL: 'http://dummyimage.com/190x100.png/cc0000/ffffff',
    OfferId: 339,
    Category: 25,
  },
  {
    title: 'Port - 74 Brights',
    description:
      'nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis aliquam convallis nunc proin at turpis a pede posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum',
    seller: 'Murphy, Bernier and Stehr',
    currentPrice: 4402,
    originalPrice: 28201,
    sellerId: 649,
    headImageURL: 'http://dummyimage.com/221x100.png/cc0000/ffffff',
    OfferId: 372,
    Category: 54,
  },
  {
    title: 'Muffin Batt - Carrot Spice',
    description:
      'sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae nisl aenean lectus pellentesque eget nunc donec quis orci eget orci vehicula condimentum curabitur in libero ut massa volutpat convallis morbi odio odio elementum eu interdum eu tincidunt in',
    seller: 'White-Lind',
    currentPrice: 31268,
    originalPrice: 39629,
    sellerId: 732,
    headImageURL: 'http://dummyimage.com/225x100.png/5fa2dd/ffffff',
    OfferId: 473,
    Category: 54,
  },
  {
    title: 'Brandy Apricot',
    description:
      'amet turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus',
    seller: 'Keeling-Streich',
    currentPrice: 16508,
    originalPrice: 39595,
    sellerId: 822,
    headImageURL: 'http://dummyimage.com/241x100.png/ff4444/ffffff',
    OfferId: 583,
    Category: 26,
  },
  {
    title: 'Pork - Ground',
    description:
      'integer aliquet massa id lobortis convallis tortor risus dapibus augue vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh in',
    seller: 'Jacobson, Anderson and Weissnat',
    currentPrice: 16359,
    originalPrice: 16729,
    sellerId: 6,
    headImageURL: 'http://dummyimage.com/161x100.png/5fa2dd/ffffff',
    OfferId: 174,
    Category: 81,
  },
  {
    title: 'English Muffin',
    description:
      'id ligula suspendisse ornare consequat lectus in est risus auctor sed tristique in tempus sit amet sem fusce consequat nulla',
    seller: 'Jacobs Inc',
    currentPrice: 36574,
    originalPrice: 37136,
    sellerId: 610,
    headImageURL: 'http://dummyimage.com/221x100.png/cc0000/ffffff',
    OfferId: 279,
    Category: 97,
  },
  {
    title: 'Cookie Choc',
    description:
      'posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel sem sed sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede libero quis orci nullam molestie nibh in lectus pellentesque at nulla suspendisse potenti cras',
    seller: 'Crist LLC',
    currentPrice: 27034,
    originalPrice: 27587,
    sellerId: 692,
    headImageURL: 'http://dummyimage.com/168x100.png/ff4444/ffffff',
    OfferId: 534,
    Category: 39,
  },
  {
    title: 'Island Oasis - Sweet And Sour Mix',
    description:
      'mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar',
    seller: 'Walter LLC',
    currentPrice: 7834,
    originalPrice: 11119,
    sellerId: 982,
    headImageURL: 'http://dummyimage.com/157x100.png/ff4444/ffffff',
    OfferId: 258,
    Category: 54,
  },
  {
    title: 'Turkey - Breast, Boneless Sk On',
    description:
      'ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet',
    seller: 'Ferry-Rippin',
    currentPrice: 29618,
    originalPrice: 27425,
    sellerId: 907,
    headImageURL: 'http://dummyimage.com/239x100.png/dddddd/000000',
    OfferId: 803,
    Category: 78,
  },
  {
    title: 'Carbonated Water - Wildberry',
    description:
      'orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio justo sollicitudin',
    seller: 'Hintz and Sons',
    currentPrice: 41542,
    originalPrice: 31118,
    sellerId: 444,
    headImageURL: 'http://dummyimage.com/188x100.png/5fa2dd/ffffff',
    OfferId: 574,
    Category: 56,
  },
  {
    title: 'Potatoes - Yukon Gold, 80 Ct',
    description:
      'mi nulla ac enim in tempor turpis nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis aliquam convallis nunc proin at turpis a pede posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue',
    seller: 'Weber, Baumbach and Kunze',
    currentPrice: 37164,
    originalPrice: 31361,
    sellerId: 39,
    headImageURL: 'http://dummyimage.com/186x100.png/dddddd/000000',
    OfferId: 264,
    Category: 86,
  },
  {
    title: 'Fork - Plastic',
    description:
      'convallis nunc proin at turpis a pede posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae',
    seller: 'Ebert, Rodriguez and DuBuque',
    currentPrice: 4575,
    originalPrice: 13358,
    sellerId: 291,
    headImageURL: 'http://dummyimage.com/100x100.png/ff4444/ffffff',
    OfferId: 160,
    Category: 23,
  },
  {
    title: 'Cattail Hearts',
    description:
      'magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae mauris viverra diam vitae quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam sit amet',
    seller: 'Yundt-Hilll',
    currentPrice: 6257,
    originalPrice: 17135,
    sellerId: 500,
    headImageURL: 'http://dummyimage.com/112x100.png/5fa2dd/ffffff',
    OfferId: 92,
    Category: 64,
  },
  {
    title: 'Flour - Rye',
    description:
      'sapien cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus etiam vel augue vestibulum rutrum rutrum neque',
    seller: 'Marquardt, Spinka and Cronin',
    currentPrice: 2872,
    originalPrice: 17416,
    sellerId: 396,
    headImageURL: 'http://dummyimage.com/219x100.png/cc0000/ffffff',
    OfferId: 881,
    Category: 5,
  },
  {
    title: 'Radish',
    description:
      'lacinia eget tincidunt eget tempus vel pede morbi porttitor lorem id ligula suspendisse ornare consequat lectus',
    seller: 'Feil, Gislason and Tromp',
    currentPrice: 16448,
    originalPrice: 15090,
    sellerId: 872,
    headImageURL: 'http://dummyimage.com/131x100.png/5fa2dd/ffffff',
    OfferId: 168,
    Category: 49,
  },
  {
    title: 'Wine - Valpolicella Masi',
    description:
      'lectus vestibulum quam sapien varius ut blandit non interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec ut dolor',
    seller: 'Mraz and Sons',
    currentPrice: 30966,
    originalPrice: 25986,
    sellerId: 376,
    headImageURL: 'http://dummyimage.com/202x100.png/dddddd/000000',
    OfferId: 208,
    Category: 24,
  },
  {
    title: 'Crackers - Melba Toast',
    description:
      'sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede libero quis orci nullam molestie nibh in lectus pellentesque at nulla suspendisse potenti cras in purus eu magna vulputate luctus cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus vivamus vestibulum',
    seller: 'Heller-Strosin',
    currentPrice: 48965,
    originalPrice: 16462,
    sellerId: 835,
    headImageURL: 'http://dummyimage.com/101x100.png/dddddd/000000',
    OfferId: 199,
    Category: 4,
  },
  {
    title: 'Foil Wrap',
    description:
      'pellentesque eget nunc donec quis orci eget orci vehicula condimentum curabitur in libero ut massa volutpat convallis morbi odio odio elementum eu interdum eu tincidunt in leo maecenas pulvinar lobortis est phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu mi nulla ac',
    seller: 'Hodkiewicz-Considine',
    currentPrice: 16591,
    originalPrice: 37368,
    sellerId: 841,
    headImageURL: 'http://dummyimage.com/186x100.png/dddddd/000000',
    OfferId: 963,
    Category: 38,
  },
  {
    title: 'Rappini - Andy Boy',
    description:
      'porttitor id consequat in consequat ut nulla sed accumsan felis ut at dolor quis odio consequat',
    seller: 'Hilll Group',
    currentPrice: 23866,
    originalPrice: 20918,
    sellerId: 290,
    headImageURL: 'http://dummyimage.com/158x100.png/dddddd/000000',
    OfferId: 597,
    Category: 78,
  },
  {
    title: 'Longos - Cheese Tortellini',
    description:
      'pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae mauris viverra diam vitae quam',
    seller: 'Watsica and Sons',
    currentPrice: 7068,
    originalPrice: 14764,
    sellerId: 575,
    headImageURL: 'http://dummyimage.com/195x100.png/ff4444/ffffff',
    OfferId: 544,
    Category: 69,
  },
  {
    title: 'Chicken - Tenderloin',
    description:
      'pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi',
    seller: 'Grant-Kreiger',
    currentPrice: 46508,
    originalPrice: 12515,
    sellerId: 161,
    headImageURL: 'http://dummyimage.com/180x100.png/dddddd/000000',
    OfferId: 97,
    Category: 64,
  },
];

export const sellers = [
  { name: 'Carrefour', route: 'userId' },
  { name: 'Mega Standard', route: 'userId' },
  { name: 'Quality Supermarket', route: 'userId' },
];

export const storeType = [
  { id: 0, name: 'Supermarket' },
  { id: 1, name: 'Clothing Brand' },
  { id: 2, name: 'Electronics Store' },
  { id: 3, name: 'Beauty and Personal Care' },
  { id: 4, name: 'Organic Market Store' },
  { id: 5, name: 'Interior Design' },
  { id: 6, name: 'Computing and Software' },
  { id: 7, name: 'Fashion and Design' },
  { id: 8, name: 'Industrial & Scientific' },
  { id: 9, name: 'Medicine and HealthCare' },
  { id: 10, name: 'Other' },
];

export const hideMenuPaths = [
  '/signup',
  '/login',
  '/welcome',
  '/registervendor',
];

export const productPaths = [
  '/dashboard/products',
  '/dashboard/create-product',
  '/dashboard/edit-product',
  '/dashboard/bulk-products',
  '/dashboard/items',
];

export const storePaths = ['/dashboard/store', '/dashboard/create-store'];
