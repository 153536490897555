import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { GoPencil } from 'react-icons/go';
import { close } from 'ionicons/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { DeviceContext } from '../../deviceType';
import { storeSchema } from '../../types/store';
import classes from './styles.module.scss';
import ErrorToast from '../error_toast';
import { useHistory } from 'react-router';
import EditStore from './editStore';
import GenericActionModal from '../genericActionModal';
import { FaLocationDot } from 'react-icons/fa6';
import { IoCall } from 'react-icons/io5';
import { IoIosMail } from 'react-icons/io';

const StoreInfoCard = ({
  store,
  action,
}: {
  store: storeSchema;
  action: () => void;
}) => {
  const size = useContext<any>(DeviceContext);
  const modal = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [_store, setStore] = useState<
    Omit<storeSchema, 'location' | 'type' | 'phoneNumber'> & {
      locations: string[];
      types: string[];
      contacts: string[];
    }
  >(
    {} as Omit<storeSchema, 'location' | 'type' | 'phoneNumber'> & {
      locations: string[];
      types: string[];
      contacts: string[];
    }
  );
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (store.id) {
      const { location, type, phoneNumber, ...rest } = store;
      setStore({
        ...rest,
        locations: location!?.split(',')!,
        contacts: phoneNumber?.split(',')!,
        types: type?.split(',')!,
      });
    }
  }, [store]);

  return (
    <IonCard
      className={classes._card + ' w-full h-fit rounded-3xl p-2 m-0 border-0'}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <GenericActionModal
        open={open}
        title="Edit Store"
        trigger="open-edit-modal"
        height={size.width >= 768 ? '85dvh' : '100dvh'}
        width={size.width >= 768 ? '50dvw' : '100dvw'}
      >
        <div className=" w-full h-[90dvh] md:h-[72dvh] overflow-y-scroll md:px-6 px-4 py-2">
          <EditStore
            close={() => {
              setOpen(false);
            }}
            store={store}
          />
        </div>
      </GenericActionModal>
      {loading && (
        <div className={classes.loading}>
          <IonSpinner name="circles" color={'secondary'}></IonSpinner>
        </div>
      )}

      <IonCardContent className={'size-full relative p-0'}>
        <div className="size-full relative p-0">
          <div
            className={'w-full h-auto flex flex-row items-start justify-start md:px-4 px-0 md:pt-4'}
          >
            <div
              className={
                'h-auto w-[30dvw] md:w-[10dvw] aspect-square rounded-full overflow-hidden bg-white border-2 border-primary mt-4 md:mt-0'
              }
            >
              <img
                className={'size-full relative object-cover object-center'}
                alt="Store Profile"
                src={store?.logo!}
              />
            </div>
            <div className="w-auto h-auto flex flex-col mt-10 md:mt-6  ml-4">
              <div className=" w-auto font-semibold text-xl text-primary font-roboto">
                {_store?.name}
              </div>
              <div
                className=" w-auto font-medium text-sm cursor-pointer text-secondary md:mb-2"
                onClick={() => history.push(`/${_store?.slug}`)}
              >
                @{_store?.slug}
              </div>
              <div className="w-full md:w-auto h-auto flex flex-row items-center flex-wrap">
                <div className="w-[15px] md:w-[18px] h-auto aspect-square flex items-center justify-center mr-2">
                  <FaLocationDot size="100%" className="text-secondary" />
                </div>
                {store.location?.split(',')!.map((item, index) => (
                  <div
                    key={index}
                    className="w-fit h-fit flex flex-row items-center justify-between pr-[2px]"
                  >
                    <div className="w-fit text-xs md:text-sm text-primary cursor-pointer ">
                      {item},
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" w-auto md:w-fit h-fit ml-[auto]">
              <IonButton
                fill="clear"
                color="secondary"
                id="open-edit-modal"
                size="small"
                className=" text-sm"
                style={{ '--border-radius': '0', '--inline-padding-start':'0', 'inline-padding-end':'0' }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <GoPencil className="mx-1 text-base" />
                Edit
              </IonButton>
            </div>
          </div>

          <div className="w-full h-auto pt-4 px-4 flex flex-col items-center">
            <div
              className={
                classes._description +
                ' pb-2 w-full h-fit font-medium text-lg text-primary'
              }
            >
              <div>{_store?.description}</div>
            </div>
            <div className="w-full h-fit grid auto-rows-auto grid-cols-2  gap-x-2 ">
              <div
                className={
                  'w-full h-fit flex flex-col items-center col-span-2 md:col-span-1'
                }
              >
                <div
                  className={
                    'w-full h-fit font-semibold text-base text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                  }
                >
                  Product Categories
                </div>
                <div
                  className={
                    'w-full h-fit flex flex-col items-start py-2 px-4 mt-2'
                  }
                >
                  {store.type?.split(',')!.map((item, index) => (
                    <div
                      key={index}
                      className="w-full h-fit flex flex-row items-center justify-between pb-2 "
                    >
                      <div className="w-fit font-medium text-lg text-[var(--ion-color-primary)]">
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={
                  'w-full h-fit flex flex-col items-center col-span-2 md:col-span-1'
                }
              >
                <div
                  className={
                    'w-full h-fit font-semibold text-base text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                  }
                >
                  Shop Contacts
                </div>
                <div
                  className={
                    'w-full h-fit flex flex-col items-start py-2 px-4 mt-2'
                  }
                >
                  {store.phoneNumber?.split(',')!.map((item, index) => (
                    <div
                      key={index}
                      className="w-full h-fit flex flex-row items-center pb-2 "
                    >
                      <div className="w-[18px] h-auto aspect-square flex items-center justify-center mr-2">
                        {index === 0 && (
                          <IoCall size="100%" className="text-secondary" />
                        )}
                      </div>

                      <div className="w-fit font-medium text-lg text-[var(--ion-color-primary)]">
                        {item}
                      </div>
                    </div>
                  ))}
                  <div className="w-full h-fit flex flex-row items-center pb-2 ">
                    <div className="w-[18px] h-auto aspect-square flex items-center justify-center mr-2">
                      <IoIosMail size="100%" className="text-secondary" />
                    </div>

                    <div className="w-fit font-medium text-base text-[var(--ion-color-primary)]">
                      {store.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default StoreInfoCard;
