import { useEffect, useMemo } from 'react';
import { Route, Redirect, useLocation } from 'react-router';
import { useHistory } from 'react-router';
import { DecodedToken, authService } from '../utils/authService';
import { is } from 'date-fns/locale';

interface ProtectedRouteProps {
  roles?: string[];
  component: any;
  path: string;
  exact: boolean;
}

const ProtectedRoute = ({
  roles,
  path,
  component: Component,
  ...props
}: ProtectedRouteProps) => {
  const location = useLocation();
  const history = useHistory();
  const loggedInUser = useMemo(
    () => authService.getCurrentUser() as DecodedToken,
    []
  );
  const _isloggedIn = loggedInUser.id;

  useEffect(() => {
    if (location.pathname.includes('admin') && !_isloggedIn) {
      history.push('/admin/login');
    }
  }, [location.pathname, _isloggedIn]);

  if (_isloggedIn) {
    let isAuthorized: boolean = false;

    if (roles!?.includes(loggedInUser.role)) {
      isAuthorized = true;
      if (path.includes('dashboard'))
        isAuthorized = loggedInUser.stores?.length! > 0;
    }

    if (!isAuthorized) {
      return <Redirect to="/" />;
    }
  }

  return (
    <Route
      {...props}
      render={(props) => {
        return authService.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          authService.redirectUser()
        );
      }}
    />
  );
};

export default ProtectedRoute;
