import  {useMemo} from 'react';
import { useHistory } from 'react-router';
import { storeSchema } from '../../../types/store';
import { capitalizeFirstLetter, formatCommaSeparatedStrings } from '../../../utils/common';

const StoreCard = (props: {
  data: storeSchema;
}) => {
  const {logo, name, type='', location=''} = props.data
  const history = useHistory();
  const newLocation = useMemo(()=>{
   return formatCommaSeparatedStrings(location)
  },[location])

  const newType = useMemo(()=>{
    return formatCommaSeparatedStrings(type)
   },[type])

  const backgroundStyle = logo?.trim()?.length
  ? { backgroundImage: `url(${logo})` }
  : { backgroundImage: 'linear-gradient(to bottom right, #a94064, #ffc0cb)' };

  return (
    <div
    className="relative w-full h-96  md:h-96 bg-cover bg-center  overflow-hidden shadow-lg text-secondary rounded-[5px]"
    style={backgroundStyle}
  >
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

    {/* Content */}
    <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4">
      <h1 className="text-2xl font-bold tracking-wide capitalize">{name}</h1>
      <p className="text-md font-medium mt-2 md:text-lg">{newType}</p>
      <p className="text-sm font-normal mt-2 italic">{newLocation}</p>
      <button
      onClick={() => {
        history.push({
          pathname: props.data.slug,
          state: { id: props.data.id },
        });
      }}
      className="mt-4 px-4 py-2 text-xs md:text-sm bg-white text-black font-semibold uppercase tracking-wide border rounded-[5px] border-black hover:bg-gray-100">
        Shop Now
      </button>
    </div>
  </div>
  );
};

export default StoreCard;
