import {
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  RefresherEventDetail,
  IonIcon,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import StoreInfoCard from '../../../components/storeInfoCard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import { GlobalContext } from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import ItemList from '../../../components/itemList';
import { ProductType } from '../../../types/product';
import StoreBar from '../../../components/storeBar';
import { storeSchema } from '../../../types/store';
import CurrentStoreCard from '../../../components/currentstorecard';
import { getCurrentStore } from '../../../utils/currentStore';
import CreateProductsModal from './modal';
import ErrorToast from '../../../components/error_toast';
import { useHistory } from 'react-router';
import { chevronUp } from 'ionicons/icons';

const Store = () => {
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const size = useContext(DeviceContext);
  // Commented out for now
  // const offers = { title: 'Offers', listItems: [] };
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [products, setProducts] = useState<ProductType[]>([
   
  ] as ProductType[]);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const history = useHistory();
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const divScrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    getStoreInfo();
  }, []);

  useEffect(() => {
    if (storeState!?.store) {
      const store = storeState.store;
      setStore(store);
      const prods = store?.products;
      setProducts(prods);
      setLoading(false);
    }
    if (storeState!?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
      setLoading(false);
    }
  }, [storeState!?.store, storeState!?.store?.id, storeState!?.storeError]);

  async function getStoreInfo() {
    const currentStore = getCurrentStore();
    let store = storeState.store as storeSchema;
    if (storeState.store && store.id! === Number(currentStore!)) {
      setStore(store);
      const prods = store?.products!;
      setProducts(prods);
    }
    await getStore(Number(currentStore))(storeDispatch);
  }

  const handleOpenEditor = () => {
    setOpenEditor(true);
  };

  const handleCreate = () => {
    history.push('/dashboard/create-product');
  };

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async () => {
      await getStoreInfo();
      e.detail.complete();
    }, 1000);
  };

  const handleDivScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    // Show button after scrolling 300px
    setShowBackToTopButton(scrollTop > 300);
  };

  const scrollToTop = () => {
    if (divScrollContainerRef.current) {
      divScrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Layout
      enableScrollToToP={true}
      enableRefresh={true}
      handleRefresh={handleRefresh}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {openEditor && (
        <CreateProductsModal open={openEditor} openCtx={setOpenEditor} />
      )}
      {loading && (
        <div className={classes.loading}>
          <IonSpinner color="secondary"></IonSpinner>
        </div>
      )}
      {!loading &&
        (size.width > 769 ? (
          <IonGrid className={classes.main}>
            <IonRow className={classes.content}>
              <IonCol size="8.5" className={classes.container}>
                <div
                  ref={divScrollContainerRef}
                  onScroll={handleDivScroll}
                  className={classes.holder + ' !px-2'}
                >
                  {/* Store Information*/}
                  <div className={classes._storeCard}>
                    <StoreInfoCard store={store!} action={handleCreate} />
                  </div>

                  {/* Product List */}
                  <div className={classes.productList}>
                    <ItemList
                      data={{ title: 'Products', listItems: products }}
                      plusAction={handleCreate}
                    />
                  </div>

                  {/* Offers List */}
                  {/* <div
                    className={classes.offerList}
                    style={{ padding: '1em 2em 0.5em 2em' }}
                  >
                    <ItemList
                      data={offers}
                      plusAction={() => {
                        openeditor(true);
                      }}
                    />
                  </div> */}

                  {showBackToTopButton && (
                    <IonIcon
                      icon={chevronUp}
                      size="small"
                      onClick={scrollToTop}
                      style={{
                        color: '#a94064',
                        background: 'white',
                        borderRadius: '50%',
                        padding: '0.5em',
                        position: 'fixed',
                        bottom: '3vh',
                        right: '50%',
                        zIndex: '10',
                        boxShadow:
                          '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  )}
                </div>
              </IonCol>
              <IonCol size="3.5" className={classes.storeBar + ' !px-2'}>
                <StoreBar />
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <div className="relative w-full h-full  px-2 overflow-y-auto">
            {/* <div className={classes.currentStore}>
							<CurrentStoreCard />
						</div> */}
            <div
              className={classes._storeCard}
              style={{ padding: '0.6em 0', height: 'auto' }}
            >
              <StoreInfoCard store={store!} action={handleCreate} />
            </div>
            <div className="w-full">
              <ItemList
                data={{ title: 'Products', listItems: products }}
                plusAction={handleCreate}
              />
            </div>
          </div>
        ))}
    </Layout>
  );
};

export default Store;
