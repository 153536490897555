import { IonButton, IonChip } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { DeviceContext } from "../../../deviceType";
import { storeSchema } from "../../../types/store";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import {
  capitalizeFirstLetter,
  formatCommaSeparatedStrings,
} from "../../../utils/common";
import { IoLocationOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";

const StorePageHead = ({ store }: { store: storeSchema }) => {
  const size = useContext(DeviceContext);
  const [locations, setLocations] = useState<string[]>([]);
  const [tel, setTel] = useState<string[]>([]);
  const [storeInfoState, setSToreInfoState] = useState<boolean>(false);
  useEffect(() => {
    const locs = store!?.location!?.split(",");
    const tel = store!?.phoneNumber?.split(",");

    setLocations(locs);
    setTel(tel!);
  }, [store]);

  return (
    <div
      className={
        "w-full flex relative px-4 flex-col items-center py-2  bg-white"
      }
    >
      {/* banner */}
      {/* <div
        className={
          "relative w-full h-[20dvh] md:h-[30dvh] hidden flex-row items-center justify-center bg-primary overflow-hidden" +
          classNames({})
        }
      >
        <div className="size-full flex  items-center justify-center overflow-hidden">
          <img
            src={store.banner}
            alt="shop banner"
            className="object-cover size-full"
          ></img>
        </div>
        <div
          className={`size-full absolute bg-[linear-gradient(rgba(246,246,246,0),rgba(246,246,246,0.3),rgba(246,246,246,0.8),#f6f6f6)]`}
        />
      </div> */}
      {/* store information holder */}
      <div
        className={`w-full mx-auto min-h-[18dvh] md:min-h-[40dvh]  grid grid-cols-2 grid-flow-row-dense gap-2 py-2  md:px-[7%]`}
      >
        <div className="col-span-2 h-auto flex items-center">
          <div className="w-[40vw] md:w-[15vw] h-auto aspect-square rounded-full overflow-hidden bg-primary">
            <img
              src={store.logo!}
              alt="shop logo"
              className="object-cover object-center size-full"
            ></img>
          </div>
          <div className="size-auto flex flex-col items-start ml-6">
            <h1 className="m-0 p-0 font-roboto text-primary text-xl md:text-3xl font-semibold">
              {store.name}
            </h1>
            {/* <Link to={store.slug!}>
              <p className="text-secondary font-roboto font-light text-base">
                @{store.slug}
              </p>
            </Link> */}

            <div className="col-span-2 h-auto flex">
              <p className="text-primary font-roboto font-medium text-sm md:text-base w-full  ">
                {capitalizeFirstLetter(store?.description || "")}
                <span
                  className={
                    "cursor-pointer text-primary font-semibold text-base  text-nowrap inline-block md:hidden"
                  }
                  onClick={() => {
                    setSToreInfoState(!storeInfoState);
                  }}
                >
                  {storeInfoState ? (
                    <IoIosArrowDown className="h-auto w-[1.3rem] aspect-square inline-block" />
                  ) : (
                    <IoIosArrowForward className="h-auto w-[1.3rem] aspect-square inline-block" />
                  )}
                </span>
              </p>
            </div>
            <div className="w-full hidden md:block">
              <MoreInfo store={store} show={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-2">
      <MoreInfo store={store} show={storeInfoState} />
      </div>
    </div>
  );
};

export default StorePageHead;

const MoreInfo = ({ store, show }: { store: storeSchema; show: boolean }) => {
  const wrapperStyle = classNames("w-full ", { hidden: !show, block: show });
  return (
    <div className={wrapperStyle}>
      <p className="w-full flex items-base my-2">
        <IoLocationOutline
          size={16}
          className=" aspect-square inline-block mr-1 text-secondary"
        />
        <span className="text-primary text-sm font-medium">
          {formatCommaSeparatedStrings(store?.location || "")}
        </span>
      </p>
      <p className="w-full flex items-base pl-1">
        <LuPhone
          size={16}
          className=" aspect-square inline-block mr-1 text-secondary"
        />
        <span className="text-primary text-sm font-medium">
          {formatCommaSeparatedStrings(store.phoneNumber || "")} 
        </span>
      </p>
      <div className="w-full flex items-start flex-wrap mt-1">
        {store.type?.split(",").map((item) => {
          return (
            <IonChip
              className="text-secondary text-xs"
              color="secondary"
              about={item}
            >
              {item}
            </IonChip>
          );
        })}
      </div>
    </div>
  );
};
