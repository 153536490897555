import { useState, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ErrorToast from "../../../components/error_toast";
import { sendVerificationEmail, verifyEmail } from "../../../utils/user/api";
import AuthLayout from "../components/AuthLayout";
import { GlobalContext } from "../../../context/provider";
import Spinner from "../../../components/spinner";

const VerifyEmail = () => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const inputsRef = useRef<any>([]);
  const location = useLocation();
    const history = useHistory();
  
  const params = new URLSearchParams(location.search);
  const userId = params.get("userId");
  const [error, setError] = useState<string | null>(null);
  const { userState } = useContext(GlobalContext);  
  const user = userState.getUser
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (index:number, value: string) => {
    if (!/^[a-zA-Z0-9]$/.test(value) && value !== "") return;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pasteData = e.clipboardData
    .getData("text") 
    .slice(0, 6)
    .replace(/[^a-zA-Z0-9]/g, "");
    if (pasteData.length === 6) {
      setCode(pasteData.split(""));
      inputsRef.current[5]?.focus();
    }
  };

  const handleSubmit = async () => {
    const _code = code.join(',')?.replace(/,/g, "")
    if(_code.length < 6){
      setError('Invalid code')
      return;
    }

    setLoading(true)

    try {
      await verifyEmail({ userId: Number(userId), code: _code});
      history.push('/login')
    } catch (error: any) {
      setError(error);
    }
    setLoading(false)

  };

  const handleResend = async () => {
    setLoading(true)
    try {
      await sendVerificationEmail({ email: user.email});
    } catch (error: any) {
      setError(error);
    }
    setLoading(false)
  };

  return (
    <AuthLayout>
      <div className="w-[93%] text-center my-12 py-8 px-3 bg-white md:w-1/2 mx-auto ">
        <h1 className="font-medium text-primary text-xl mb-8">Verify your Email</h1>
        <p className="my-4 font-light">A verification code was sent to <span className="text-primary-tint font-medium">{user?.email}</span>. Enter the code. </p>
        <div className="flex space-x-2 justify-center w-full md:w-1/2 mx-auto mt-6">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputsRef.current[index] = el)}
              type="text"
              value={digit}
              maxLength={1}
              className="w-11 h-12 md:w-12 text-center text-lg border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => handleChange(index, e.target.value)}
              onPaste={handlePaste}
            />
          ))}
        </div>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-primary text-white font-semibold rounded-md focus:outline-none  mt-6"
        >
          {loading?<Spinner color="white"  /> :'Submit'}
        </button>
        <ErrorToast
          message={error!}
          type={"error"}
          isOpen={!!error}
          onClose={() => {
            setError(null);
          }}
          position="top"
        />

        {!loading && <p className="text-sm my-4 ">Did not recieve code? <span onClick={handleResend} className="text-secondary-tint text-decoration cursor-pointer">Resend</span></p>}

      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
