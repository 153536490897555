import {
  IonButton,
  IonRippleEffect,
  IonTabBar,
  IonTabButton,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { gsap } from 'gsap';
import DashProductCard from '../cards/dashboardProduct';
import Spinner from '../spinner';
import { getElementPosition } from '../../utils/getElementPosition';
import { group } from 'console';
import classNames from 'classnames';
import { ProductType } from '../../types/product';

interface TabData {
  label: string;
  default?: boolean;
  data: ProductType[];
  type: 'views' | 'likes';
}

/**
 * Can display data in separate tabs, the default tab is declared by adding the default property to it
 * The component can be extended to display data using different cards or no card at all, for now Ive implemented
 * display for the home card only and if needed other cards can be addedto the display
 *
 * @param {TabData[]} - data
 * @returns {React.FC}
 */
const MultipleTabCard = ({ data }: { data: TabData[] }) => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<TabData>();
  const [_data, setData] = useState<ProductType[]>([]);
  const selectorRef = useRef<any>();
  const activeTabRef = useRef<any>();

  useEffect(() => {
    const labels = data.map((group) => {
      return group.label;
    });
    const defaultTab = data.find((tab, index) => {
      return tab.default;
    });
    if (defaultTab) {
      setData(defaultTab.data);
      setActiveTab(defaultTab);
    }
    setTabs(labels);
  }, [data]);

  useEffect(() => {
    if (activeTabRef.current) {
      const activeTabPosition = getElementPosition(activeTabRef.current);
      const selectorPosition = getElementPosition(selectorRef.current);
      const slideDistance = activeTabPosition.x - selectorPosition.x;
      gsap.to(selectorRef.current, {
        x: slideDistance,
        width: activeTabRef.current.getBoundingClientRect().width,
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    }
    const activeData = activeTab?.data;
    if (activeData) setData(activeData);
  }, [activeTab]);

  const setActive = (e: any) => {
    const labels = e.target.title;
    const _tab = data.find((group) => group.label === labels);
    setActiveTab(_tab!);
    activeTabRef.current = e.target;
  };
  return (
    <div
      className={classes.ProductsCard + ' !w-full bg-white rounded-3xl !p-4'}
    >
      <div className={' w-full h-auto flex items-center justify-start mb-4'}>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              title={tab}
              ref={tab === activeTab?.label! ? activeTabRef : undefined}
              onClick={setActive}
              className={
                'ion-activatable ripple-parent circle overflow-hidden relative text-primary text-roboto text-base font-semibold w-auto h-auto p-4 hover:bg-gray-50 cursor-pointer rounded-2xl mr-2 ' +
                classNames({
                  ' !text-secondary bg-gray-100': tab === activeTab?.label!,
                })
              }
            >
              <IonRippleEffect color="primary" />
              {tab}
            </div>
          );
        })}
      </div>
      <div
        className={
          'w-full h-auto grid md:grid-cols-3 grid-cols-2 grid-flow-row-dense gap-2 '
        }
      >
        {_data.length > 0 ? (
          _data.map((item, index) => {
            if (activeTab?.type === 'likes' && !item.bookmarks!.length)
              return <></>;
            if (activeTab?.type === 'views' && !item.views!.length)
              return <></>;
            return (
              <div className="h-auto flex items-center justify-center mb-2">
                <DashProductCard
                  data={item}
                  key={index}
                  type={activeTab?.type!}
                />
              </div>
            );
          })
        ) : (
          <div className="w-full h-full flex justify-center items-center text-[var(--ion-color-primary)] text-base font-semibold">
            Nothing to Show!
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleTabCard;
