import {
  IonAvatar,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { getDashboardRoutes } from '../../utils/routes';
import classes from './styles.module.scss';
import { GlobalContext } from '../../context/provider';
import { useContext, useEffect, useMemo, useState } from 'react';
import UserProfile from '../storeMenu/userProfile';
import { useLocation } from 'react-router';
import { closeOutline } from 'ionicons/icons';
import logo from '../../Assets/logo/logo_clear_96x96.svg';
import { authService } from '../../utils/authService';
import { getCurrentUser } from '../../utils/getClientInfo';
import { getCurrentStore } from '../../utils/currentStore';
import { productPaths, storePaths } from '../../utils';

const SellerSlideMenu = (props: { user: any; action: any }) => {
  const { userState } = useContext<any>(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const userDetails = userState.getUser;
  const [currentPath, setCurrentPath] = useState<string>('');
  const currentUser = getCurrentUser();
  const currentStore = getCurrentStore();
  const storeSlug = currentUser.stores?.find(
    (i, n) => i.id === Number(currentStore!)
  );
  
  useEffect(() => {
    const path = location.pathname;
    setCurrentPath(path);
    
    // TODO: refactor when child paths are updated
    const pathToCompare = path.split('/detail')[0];
    if (productPaths.some(str => str.includes(pathToCompare))) {
      setCurrentPath('/dashboard/products');
    }
    if (storePaths.some(str => str.includes(pathToCompare))) {
      setCurrentPath('/dashboard/store');
    }
  }, [location?.pathname]);

  const dashboardRoutes = useMemo(() => {
    if(location){
      return getDashboardRoutes({ location });
    }
    return []
  }, [location]);

  return (
    <div className={classes['seller-slidemenu']}>
      <div className={classes.header}>
        <IonRow className={classes['logo-close-row']}>
          <IonCol size="10">
            <div
              className={classes.logo}
              onClick={async() => {
                await props.action.close(); // close menu after click
                history.push('');
              }}
            >
              <IonIcon src={logo}></IonIcon>
              <span className={classes.span1}>Bazaar</span>{' '}
              <span className={classes.span2}>Cheap</span>
            </div>
          </IonCol>
          <IonCol size="2">
            <div
              className={classes.close + ' ion-activatable ripple-parent circle'}
              onClick={async() => {
                await props.action.close();
              }}
            >
              <IonRippleEffect></IonRippleEffect>
              <IonIcon icon={closeOutline} color={'secondary'}></IonIcon>
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          className={classes['user-details-row']}
          onClick={async() => {
            await props.action.close(); // close menu after click
            history.push('/account');
          }}
        >
          <IonCol>
            {!userDetails ? (
              <IonLoading
                isOpen
                message="loading..."
                duration={1000}
              ></IonLoading>
            ) : (
              <>
                <IonItem lines="none" detail={false}>
                  <IonAvatar slot="start">
                    <UserProfile
                      color="#A94064"
                      placeholder={`${userDetails?.firstName?.[0]?.toUpperCase()} ${userDetails?.lastName?.[0]?.toUpperCase()}`}
                    />
                  </IonAvatar>
                  <IonLabel color="secondary">
                    {userDetails?.firstName.charAt(0).toUpperCase() +
                      userDetails?.firstName.slice(1)}{' '}
                    {userDetails?.lastName.charAt(0).toUpperCase() +
                      userDetails?.lastName.slice(1)}
                  </IonLabel>
                </IonItem>
              </>
            )}
          </IonCol>
        </IonRow>
      </div>

      <div className={classes['menu-items']}>
        {dashboardRoutes.map((item, index) => {
          if (item.icon) {
            return (
              <IonItem
                key={index}
                lines="none"
                detail={false}
                button
                routerDirection="root"
                routerLink={
                  item.path.includes(':')
                    ? item.path.split(':')[0] + storeSlug?.slug
                    : item.path
                }
                onClick={async() => {
                  await props.action.close(); // close menu after click
                  item.path === '/login' && authService.logoutUser();
                }}
                className={`menu-item ${currentPath === item.path ? classes.selected : ''}`}
              >
                <IonIcon slot="start" icon={item.icon} />
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default SellerSlideMenu;
