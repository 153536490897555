import React, { useContext, useEffect } from 'react';
import Layout from '../../../components/Layout/user/Layout';
import classes from './styles.module.scss';
import { GlobalContext } from '../../../context/provider';
import { getWishList } from '../../../context/actions/products';
import ProductBlock from '../../../components/cards/Product_Block';

const WishList = () => {
  const { productState, productDispatch } = useContext<any>(GlobalContext);

  useEffect(() => {
    getWishList()(productDispatch);
  }, []);

  const wishlistProducts = productState.wishlist;

  return (
    <Layout enableScrollToToP={true}>
      <div className="w-full px-4 md:px-8 ">
          <div className={classes.head}>
            <div className={classes.title}>Favorites</div>
          </div>
          <div
            className={'w-full grid grid-cols-2 md:grid-cols-6 gap-2'}
         >
            {!wishlistProducts?.length && (
              <p className={classes['no-favorites']}>
                Sorry, you don't have any favorites yet!
              </p>
            )}
            {wishlistProducts?.map((item: any, index: number) => {
              return (
                <ProductBlock data={item.product} key={index}/>
              );
            })}
          </div>
      </div>
    </Layout>
  );
};

export default WishList;
