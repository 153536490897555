import { IonGrid, IonRow, IonCol, RefresherEventDetail } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout/user/Layout';
import { searchProducts } from '../../../context/actions/products';
import { getStore } from '../../../context/actions/store';
import {
  GET_STORE_ERROR,
  SEARCH_PRODUCTS,
} from '../../../context/actions/types';
import { GlobalContext } from '../../../context/provider';
import classes from './styles.module.scss';
import StoreProducts from './store-products';
import StorePageHead from './topBar';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import { storeSchema } from '../../../types/store';

const SingleStore = () => {
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const location = useLocation();
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const params = useParams<any>();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const slug = location.pathname.split('/')[1];
    if (slug) {
      fetchStore(slug);
    }
  }, []);

  useEffect(() => {
    if (storeState?.store && storeState.store.slug === params.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState.storeError!?.response!?.data!?.message
          ? storeState.storeError!?.response!?.data!?.message
          : storeState.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState?.store, storeState.storeError]);

  async function fetchStore(e: any) {
    setLoading(true);
    if (storeState?.store && storeState.store.slug === params.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
      return;
    }
    await getStore(e)(storeDispatch);
  }

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async () => {
      const storeSlug = location.pathname.split('/')[1];
      await fetchStore(storeSlug);
      e.detail.complete();
    }, 1000);
  };

  return (
    <Layout
      enableScrollToToP={true}
      enableRefresh={true}
      handleRefresh={handleRefresh}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner type="circles" />
        </div>
      ) : (
        <div className={'w-full h-auto flex flex-col items-start'}>
          <div className="w-full flex items-center">
            <StorePageHead store={store!} />
          </div>
          <div className="w-full h-auto flex items-center mt-2">
            <StoreProducts store={store} products={products} />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SingleStore;
