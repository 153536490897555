import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import classes from './style.module.scss';
import { getDashboardRoutes } from '../../../../utils/routes';
import { getCurrentUser } from '../../../../utils/getClientInfo';
import { getCurrentStore } from '../../../../utils/currentStore';
import { authService } from '../../../../utils/authService';
import { productPaths, storePaths } from '../../../../utils';

const SellerMenu = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>('');
  const currentUser = getCurrentUser();
  const currentStore = getCurrentStore();
  const storeSlug = currentUser.stores?.find(
    (i, n) => i.id === Number(currentStore!)
  );

  useEffect(() => {
    const path = location.pathname;
    setCurrentPath(path);
    
    // TODO: refactor when child paths are updated
    const pathToCompare = path.split('/detail')[0];
    if (productPaths.some(str => str.includes(pathToCompare))) {
      setCurrentPath('/dashboard/products');
    }
    if (storePaths.some(str => str.includes(pathToCompare))) {
      setCurrentPath('/dashboard/store');
    }
  }, [location.pathname]);
  
  const dashboardRoutes = useMemo(() => {
    return getDashboardRoutes({ location });
  }, [location]);

  const handleMenuItemClick = (path: string) => {
    if (path === '/login') {
      authService.logoutUser();
    }
  };
  return (
    <IonList className={classes['menu-items']}>
      {dashboardRoutes.map((item, index) => {
        if (item.icon) {
          return (
            <IonItem
              key={index}
              lines="none"
              detail={false}
              button
              routerDirection="root"
              routerLink={
                item.path.includes(':')
                  ? item.path.split(':')[0] + storeSlug?.slug
                  : item.path
              }
              onClick={() => handleMenuItemClick(item.path)}
              className={`${currentPath === item.path ? classes.selected : ''} ${item.path === '/login' ? classes['last-item'] : ''}`}
            >
              <IonIcon slot="start" icon={item.icon} />
              <IonLabel>{item.name}</IonLabel>
            </IonItem>
          );
        }
        return null;
      })}
    </IonList>
  );
};

export default SellerMenu;
