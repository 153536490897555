import { IonGrid, IonRow, IonCol } from "@ionic/react";
import FaqsItem from "../../../components/Faqs";
import Layout from "../../../components/Layout/user/Layout";
import { FaqsType } from "../../../types/faqs";
import classes from "./styles.module.scss";
import whatsappIcon from "../../../Assets/whatsappIcon.svg";
import callIcon from "../../../Assets/callIcon.svg";
import CopyIcon from "../../../components/copyIcon";
import { faqs } from "../../../data/faqs";
import { Link } from "react-router-dom";
import { useState } from "react";
import Btn from "../../../components/Btn";
import ContactUsForm from "../../../components/contactusForm/contact";
import { MdMessage } from "react-icons/md";
import { IoMdCall } from "react-icons/io";

const HelpCenter = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const filterQuestions = faqs.filter((item: FaqsType, index: number) => {
    return index <= 4;
  });

  const numbers = ["0784331902", "0788536892", "0781590902"];

  const filter = numbers.filter((number) => {
    return number === "0784331902";
  });
  const filterWhatsapp = numbers.filter((number) => {
    return number === "0781590902";
  });
  const filterCall = numbers.filter((number) => {
    return number === "0788536892";
  });

  return (
    <Layout>
      <section className="w-full px-4 py-6 md:px-28">
        {/* <IonRow className={classes.help}>
          <IonCol>
            <div className={classes.divhelp}>
              <h1>Help Center</h1>
              <div className={classes.searchflex}>
                <input value="Enter keyword" />
                <div className={classes.button}>
                  <Btn match={true} name="search" loading={loading} />
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow> */}
        <div className="w-full">
          <h2 className="text-lg font-medium text-primary">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="w-full">
          {filterQuestions.map((item: FaqsType, index: number) => (
            <FaqsItem
              key={index}
              position={index}
              title={item.title}
              content={item.content}
            />
          ))}
          <div className="w-full flex justify-end my-4 text-secondary-tint">
            <Link to="/faqs">View all</Link>
          </div>
        </div>
        <h3 className="w-full font-medium text-lg">Contact Support</h3>
        <div className="w-full mt-4 md:flex md:gap-4">
          <div className="w-full md:w-1/2">
            <div className="w-full hidden items-center gap-2  text-lg  md:flex">
              <MdMessage className="text-secondary text-2xl" />
              <h6>Message</h6>
            </div>

            <p className={classes.paragraph}>
              Write to Support with detailed description of the issue. We will
              get back to you.{" "}
            </p>
            <ContactUsForm />
          </div>
          <div className="w-full mt-4 md:w-1/2">
            <div className="w-full hidden items-center gap-2 text-lg md:flex">
              <IoMdCall className="text-secondary text-2xl" />
              <h6>Call/WhatsApp</h6>
            </div>
            <p className="my-5">
              You can also contact support on call/whatsapp on the numbere
              below.
            </p>
            <div className="w-full mt-4">
              <div className="w-full flex justify-between">
                <p>+256784331902</p>
                <div className="flex gap-1">
                  <a href="tel:+256784331902">
                    <img src={callIcon} />
                  </a>
                  <a
                    href="https://wa.me/256784331902"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsappIcon} />
                  </a>
                  <CopyIcon phonenum="0784331902" />
                </div>
              </div>
              <hr className="my-1" />
              <div className="w-full flex justify-between">
                <p>+256751031187</p>
                <div className="flex gap-1">
                  <a href="tel:+256751031187">
                    <img src={callIcon} />
                  </a>
                  <a
                    href="https://wa.me/256751031187"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsappIcon} />
                  </a>
                  <CopyIcon phonenum="256751031187" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default HelpCenter;
