import { useParams } from 'react-router';
import Layout from '../../../../components/Layout/admin';
import classes from './styles.module.scss';
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../../context/provider';
import {  GET_ADMIN_USER,
  GET_ADMIN_USER_ERROR,
} from '../../../../context/actions/types';
import ErrorToast from '../../../../components/error_toast';
import Spinner from '../../../../components/spinner';
import { HiChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import { getAdminUser } from '../../../../context/actions/admin/user';

interface Store {
    id: number | string;
    name: string;
    type: string;
    status: string;
    createdAt: string;
  }

interface Admin {
  id: number | string;
  firstName: string;
  lastName: string;
  stores: Store[];
  role: string;
  email: string;
  phoneNumber: string;
}

const AdminUserDetails: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { adminUserState, adminUserDispatch } =
    useContext<any>(GlobalContext);
  const [user, setUser] = useState<Admin>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  ;

  useEffect(() => {
    fetchAdminStore();
  }, []);

  useEffect(() => {
    if (adminUserState?.adminUser) {
      const user = adminUserState?.adminUser;
      setUser(user);
      adminUserDispatch({ type: GET_ADMIN_USER, payload: null });
    }
    if (adminUserState?.adminUserError) {
      setShowError(true);
      setErrorMessage(adminUserState?.adminUserError);
      adminUserDispatch({ type: GET_ADMIN_USER_ERROR, payload: null });
    }
  }, [adminUserState, adminUserDispatch]);


  const fetchAdminStore = async () => {
    setLoading(true);
    await getAdminUser(id!)(adminUserDispatch);
    setLoading(false);
  };


  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={showError}
        onClose={() => {
          setShowError(false);
        }}
      />
      <div className={classes.header}>
        <div className={classes.sec}>
          <Link to="/admin/dashboard/users">
            <HiChevronLeft className={classes.arrow} />
          </Link>
          <p className={classes.heading}>User Details</p>
        </div>

      </div>
      <div className={classes.main}>
        <div className={classes.details}>
          <div className={classes.para2}>
            <p>ID</p>
            <p>First Name</p>
            <p>Last Name</p>
            <p>Role</p>
            <p>Stores</p>
            <p>Email</p>
            <p>Phone Number(s)</p>

          </div>
          {user && (
            <div className={classes.para3}>
              <p>{id}</p>
              <p>{user.firstName}</p>
              <p>{user.lastName}</p>
              <p>{user.role}</p>
              {user?.stores?.length>0 ?
              user.stores?.map((store: Store)=> <p key={store.id} style={{ color: '#007BFF' }}>
                <Link to={`/admin/dashboard/users/${store.id}`}>{store.name}</Link>
              </p>): <p>no store</p>}
               <p>{user.email}</p>
              <p>{user.phoneNumber}</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminUserDetails;
