import { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import InputCard from '../../../components/inputField';
import { Store } from '../../../types/store';
import SelectField from '../../../components/selectField';
import { GlobalContext } from '../../../context/provider';
import {
  CREATE_PRODUCT_ERROR,
  GET_PRODUCT_TYPES_ERROR,
} from '../../../context/actions/types';
import {
  addCircleOutline,
  addOutline,
  addSharp,
  closeOutline,
  closeSharp,
} from 'ionicons/icons';
import { User } from '../../../types/user';
import { getStoreTypes } from '../../../context/actions/store';
import ErrorToast from '../../../components/error_toast';
import { DeviceContext } from '../../../deviceType';
import useGlobalContext from '../../../hooks/useStore';

export const Info = ({
  store,
  storeUpdate,
  loading,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  store: Store;
  loading: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const {
    userState,
    productTypes,
    productTypesDispatch,
    storeTypesDispatch,
    storeTypes,
  } = useContext<any>(GlobalContext);
  const size = useContext(DeviceContext);
  const [info, setInfo] = useState<Omit<Store, 'type'> & { type: string[] }>(
    {} as Omit<Store, 'type'> & { type: string[] }
  );
  const [types, setTypes] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [user, setUser] = useState<User>({} as User);
  const helperText = {
    description:
      'A brief description about the shop and the products offered. It must be atleast 6 characters.',
  };

  useEffect(() => {
    fetchTypes();
    const { type, ...rest } = store;
    setInfo({ ...rest, type: type?.split(',') || [] });
  }, []);

  useEffect(() => {
    const _user = userState?.getUser!;
    if (_user?.id) setUser({ ..._user });
  }, [userState.getUser]);
  useEffect(() => {
    if (user?.id && user.phoneNumber) {
      setInfo({ ...info, email: user.email, contacts: [user.phoneNumber] });
    }
  }, [user]);

  useEffect(() => {
    handleInput();
  }, [info]);

  useEffect(() => {
    if (storeTypes.storeTypes) {
      const _types = storeTypes.storeTypes;
      setTypes(_types);
      loading(() => false);
    }
    if (productTypes.productTypesError) {
      setMessage(
        productTypes.productTypesError.response?.data
          ? productTypes.productTypesError.response.data.message
          : productTypes.productTypesError.message
      );
      productTypesDispatch({ type: GET_PRODUCT_TYPES_ERROR, payload: null });
    }
  }, [storeTypes.storeTypes, loading]);

  const fetchTypes = async () => {
    if (storeTypes.storeTypes?.length) {
      loading!(() => false);
      return setTypes(storeTypes.storeTypes);
    }
    await getStoreTypes()(storeTypesDispatch);
  };

  const handleInput = () => {
    const { type, ...rest } = info;
    storeUpdate((params: any) => {
      return {
        ...params,
        ...rest,
        type: type?.length && type.join(','),
      };
    });
  };
  const handleEnter = (e: any) => {
    if (e.target.name === 'name') {
    }
    if (e.target.name === 'description') {
    }
  };
  return (
    <>
      <div className={classes.info}>
        <div className={classes.typography}>
          <IonGrid>
            <IonRow
              className={classes.head}
              style={{ height: size.width >= 768 ? '20%' : 'auto' }}
            >
              <IonCol
                size={size.width <= 768 ? '12' : '6'}
                className={classes.name}
              >
                <div className={classes.label + ' text-primary'}>Shop Name</div>
                <div
                  className={classes.input}
                  style={{ height: size.width >= 768 ? '65%' : 'auto' }}
                >
                  <InputCard
                    type="text"
                    placeholder="Store Name"
                    name="name"
                    inputType="text-field"
                    required
                    value={setInfo}
                    formValues={info}
                    enterEvent={handleEnter}
                  />
                </div>
              </IonCol>
              { size.width > 786 &&<IonCol
                size="6"
                className={classes.cat}
                style={{ display: size.width <= 768 ? 'none' : 'unset' }}
              >
                <div className={classes.label + ' text-primary'}>Product/Service Categories</div>
                <div
                  className={classes.input}
                  style={{ height: size.width >= 768 ? 'auto' : 'auto' }}
                >
                  <SelectField
                    value={setInfo}
                    name={'type'}
                    options={types}
                    multiple
                    formValues={info}
                    placeholder="Store Types"
                    mode="md"
                  />
                </div>
              </IonCol>}
            </IonRow>
            {size.width <= 786 && (
              <IonRow className={classes.type}>
                <IonCol size="12">
                  <div className={classes.label + ' text-primary'}>
                    Product/Service Categories
                  </div>
                  <div
                    className={classes.input}
                    style={{ height: size.width >= 768 ? 'auto' : 'auto' }}
                  >
                    <SelectField
                      value={setInfo}
                      name={'type'}
                      options={types}
                      formValues={info}
                      placeholder="Shop Types"
                      mode="md"
                      multiple
                    />
                  </div>
                </IonCol>
              </IonRow>
            )}
            <IonRow className={classes.description}>
              <IonCol size="12">
                <div className={classes.label + ' text-primary'}>Shop Description</div>
                <div className={classes.helperText}>
                  {helperText.description}
                </div>
                <div className={classes.container}>
                  <InputCard
                    type="text"
                    placeholder="Shop Description"
                    name="description"
                    inputType={'text-box'}
                    required
                    maxLength={256}
                    value={setInfo}
                    formValues={info}
                    enterEvent={handleEnter}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );
};

export const Contacts = ({
  store,
  storeUpdate,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  authNext: (arg0: (form: boolean | null) => any) => any;
  store: Store;
  loading?: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const globalState = useGlobalContext();
  const size = useContext(DeviceContext);
  const [info, setInfo] = useState<Store>({} as Store);
  const [currentContact, setCurrentContact] = useState<{ current: string }>(
    {} as any
  );
  const addref = useRef<any>();
  const helperText = {
    contact:
      'Add a phone number by entering the number into the field below and clicking add. You can add a maximum of 5 phone numbers ',
  };

  useEffect(() => {
    const user = globalState.userState.getUser;
    if (user && !store.email) {
      setInfo({ ...store, email: user.email || '' });
    } else {
      setInfo({ ...store });
    }
  }, []);

  useEffect(() => {
    handleInput();
  }, [info]);
  const handleInput = () => {
    const { contacts, ...rest } = info;
    let conts: string[];
    if (contacts && contacts.length !== 5 && currentContact.current) {
      conts = [...contacts];
      return storeUpdate((params: any) => {
        return {
          ...params,
          ...rest,
          contacts: conts,
        };
      });
    }
    storeUpdate((params: any) => {
      return {
        ...params,
        ...info,
      };
    });
  };
  const handlecontacts = () => {
    if (currentContact!.current) {
      if (info.contacts && info.contacts.length) {
        setInfo({
          ...info,
          contacts: [...info.contacts, currentContact!.current],
        });
      } else {
        setInfo({
          ...info,
          contacts: [currentContact!.current],
        });
      }
    }

    setCurrentContact({ current: '' });
  };
  const handleEnter = (e: any) => {
    if (e.target.name === 'current') {
      addref.current.click();
    }
    if (e.target.name === 'email') {
    }
  };
  return (
    <div className={classes.contactContainer}>
      <div
        className={classes.email}
        style={{ height: size.width >= 768 ? '4.5rem' : '4.5rem' }}
      >
        <div className={classes.label + ' text-primary'}>Shop Email</div>
        <div className={classes.input}>
          <InputCard
            type="text"
            placeholder="Shop Email"
            inputType="text-field"
            name="email"
            required
            value={setInfo}
            formValues={info}
            enterEvent={handleEnter}
          />
        </div>
      </div>
      <div className={classes.contacts}>
        <div className={classes.label + ' text-primary'}>Contact Details </div>
        <div className={classes.helperText}>{helperText.contact}</div>
        <div className={classes.container}>
          <div className={classes.input}>
            <div className={classes.field}>
              <InputCard
                type="tel"
                placeholder="Shop PhoneNumber - +256 000 000 000"
                name="current"
                inputType={'text-field'}
                required
                onBlur={() => {
                  handlecontacts();
                }}
                maxLength={13}
                value={setCurrentContact}
                formValues={currentContact}
                enterEvent={handleEnter}
              />
            </div>

            <IonButton
              onClick={handlecontacts}
              color="secondary"
              ref={addref}
              disabled={info.contacts?.length !== 5 ? false : true}
            >
              Add
            </IonButton>
          </div>
          <div
            className={classes.list + ' !bg-white'}
            style={{ display: info.contacts!?.length ? 'flex' : 'none' }}
          >
            {info.contacts?.map((item: string, index) => {
              return (
                <div key={index} className={classes.item}>
                  {item}
                  <IonButton
                    onClick={() => {
                      const list = info.contacts;
                      const filtered = list?.filter((i, n) => {
                        return n !== index;
                      });

                      setInfo({ ...info, contacts: [...filtered!] });
                    }}
                    fill={'clear'}
                    className={classes.btn}
                  >
                    <IonIcon
                      icon={closeSharp}
                      color={'secondary'}
                      size="default"
                    />
                  </IonButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Location = ({
  store,
  storeUpdate,
  loading,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  store: Store;
  loading?: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const [info, setInfo] = useState<Store>({} as Store);
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [currentLocation, setCurrentLocation] = useState<{ current: string }>(
    {} as any
  );
  const size = useContext(DeviceContext);
  const addref = useRef<any>();
  const [_error, setError] = useState<{
    state: boolean;
    msg: string;
  }>({
    state: false,
    msg: '',
  });
  const [message, setMessage] = useState<string>('');
  const logoInput = useRef<any>(null);
  const bannerInput = useRef<any>(null);
  const helperText = {
    locations:
      'Add a location by entering the location into the field below and clicking add. You can add a maximum of 8 locations',
  };

  useEffect(() => {
    setInfo({ ...store });
  }, []);

  useEffect(() => {
    if (storeState.storeError) {
      setError({
        state: true,
        msg: storeState.storeError.response?.data
          ? storeState.storeError.response.data.message
          : storeState.storeError.message,
      });
      storeDispatch({ type: CREATE_PRODUCT_ERROR, payload: null });
      // update loading state
      loading!((e: boolean) => {
        return !e;
      });
    }
  }, [storeState]);

  useEffect(() => {
    handleInput();
  }, [info]);

  useEffect(() => {
    if (logoInput.current) {
      logoInput.current.addEventListener('drop', (e: DragEvent) =>
        handleDrop(e, 'logo')
      );
      logoInput.current.addEventListener('change', (e: Event) =>
        handleClick(e, 'logo')
      );
    }
    if (bannerInput.current) {
      bannerInput.current.addEventListener('drop', (e: DragEvent) =>
        handleDrop(e, 'banner')
      );
      bannerInput.current.addEventListener('change', (e: Event) =>
        handleClick(e, 'banner')
      );
    }
  }, [logoInput, bannerInput, info]);

  const handleDrop = (e: DragEvent, item: string) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer?.files[0];
    if (file!.size > Math.floor(3 * 1048576)) {
      return setMessage('The File Limit is 3Mb!');
    }
    setInfo({ ...info, [item]: file });
  };

  const handleClick = (e: Event, item: string) => {
    e.stopPropagation();
    e.preventDefault();
    const file = (e.target! as HTMLInputElement)!?.files![0];
    if (file.size > Math.floor(3 * 1048576)) {
      return setMessage('The File Limit is 3Mb!');
    }
    setInfo({ ...info, [item]: file });
  };

  const handleInput = () => {
    const { location, ...rest } = info;
    let locs: string[];
    if (location && location.length !== 5 && currentLocation.current) {
      locs = [...location];
      return storeUpdate((params: any) => {
        return {
          ...params,
          ...rest,
          location: locs,
        };
      });
    }
    storeUpdate((params: any) => {
      return {
        ...params,
        ...info,
      };
    });
  };
  const handleLocations = () => {
    if (currentLocation!.current) {
      if (info.location && info.location.length) {
        setInfo({
          ...info,
          location: [...info.location!, currentLocation!.current],
        });
      } else {
        setInfo({
          ...info,
          location: [currentLocation!.current],
        });
      }
    }

    setCurrentLocation({ current: '' });
  };
  const handleEnter = (e: any) => {
    addref.current.click();
  };
  return (
    <div className={classes.location}>
      <div
        className={classes.images}
        style={{ height: size.width >= 768 ? '28dvh' : '20dvh' }}
      >
        <div className={classes.cont}>
          <div className={classes.profile}>
            <div className={classes.label + ' text-primary'}>Logo</div>
            <div className={classes.imageHolder + ' bg-primary-shade'}>
              {!info.logo && (
                <div
                  className={
                    classes.input + ' ion-activatable ripple-parent circle'
                  }
                  title="Click or Drag and Drop to Add Logo"
                  onClick={() => {
                    logoInput?.current.click();
                  }}
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonIcon icon={addOutline} size="large"></IonIcon>
                  <input ref={logoInput} type={'file'} accept={'image/*'} />
                </div>
              )}
              {info.logo && (
                <div className={classes.display}>
                  <div
                    className={classes.discard}
                    style={{
                      top: size.width >= 768 ? '5%' : '5%',
                      left: size.width >= 768 ? '12%' : '25%',
                    }}
                  >
                    <IonButton
                      color={'secondary'}
                      fill={'solid'}
                      onClick={() => {
                        setInfo({ ...info, logo: undefined });
                      }}
                    >
                      <IonIcon
                        icon={closeOutline}
                        slot={'icon-only'}
                        color={'light'}
                      />
                    </IonButton>
                  </div>
                  <img
                    src={URL.createObjectURL(info.logo! as File)}
                    alt={'Store Logo'}
                  />
                </div>
              )}
            </div>
          </div>
          {/*
          <div
            className={classes.banner}
            style={{ width: size.width >= 768 ? '100%' : '100%' }}
          >
            <div className={classes.label + ' text-primary'}>Banner</div>
            <div className={classes.imageHolder}>
              <div className={classes.overlay}>
                {!info.banner && (
                  <div
                    className={
                      classes.input + ' ion-activatable ripple-parent circle'
                    }
                    title={'Click or Drag and Drop to Add Banner'}
                    onClick={() => {
                      bannerInput?.current.click();
                    }}
                  >
                    <IonIcon
                      icon={addSharp}
                      color="primary"
                      size="large"
                    ></IonIcon>
                    <IonRippleEffect></IonRippleEffect>
                    <input ref={bannerInput} type={'file'} accept={'image/*'} />
                  </div>
                )}
              </div>
              {info.banner && (
                <div className={classes.display}>
                  <div className={classes.discard}>
                    <IonButton
                      color={'secondary'}
                      fill={'solid'}
                      onClick={() => {
                        setInfo({ ...info, banner: undefined });
                      }}
                    >
                      <IonIcon
                        icon={closeOutline}
                        slot={'icon-only'}
                        color={'light'}
                        size="large"
                      />
                    </IonButton>
                  </div>
                  <img
                    src={URL.createObjectURL(info.banner! as File)}
                    alt={'Store Banner'}
                  />
                </div>
              )}
            </div>
          </div> */}
          {message && (
            <div className={classes.message}>
              <p>{message}</p>
              <IonButton
                color="secondary"
                size="small"
                fill="clear"
                onClick={() => {
                  setMessage('');
                }}
              >
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </div>
          )}
        </div>
      </div>
      <div
        className={classes.holder}
        style={{ height: size.width >= 768 ? '60%' : 'auto' }}
      >
        <div className={classes.label + ' text-primary'}>Location Details</div>
        <div className={classes.helperText}>{helperText.locations}</div>
        <div className={classes.container}>
          <div className={classes.input}>
            <div className={classes.field}>
              <InputCard
                type="text"
                placeholder="Shop Locations"
                name="current"
                inputType={'text-field'}
                required
                onBlur={() => {
                  handleLocations();
                }}
                maxLength={32}
                value={setCurrentLocation}
                formValues={currentLocation}
                enterEvent={handleEnter}
              />
            </div>

            <IonButton
              onClick={handleLocations}
              ref={addref}
              color="secondary"
              disabled={info.location?.length !== 8 ? false : true}
            >
              Add
            </IonButton>
          </div>
          <div
            className={classes.list + ' !bg-white '}
            style={{ display: info.location!?.length ? 'flex' : 'none' }}
          >
            {(info.location! as string[])?.map((item, index) => {
              return (
                <div key={index} className={classes.item}>
                  {item}
                  <IonButton
                    onClick={() => {
                      const list = info.location;
                      const filtered = (list as string[])?.filter((i, n) => {
                        return n !== index;
                      });

                      setInfo({ ...info, location: [...filtered!] });
                    }}
                    fill={'clear'}
                    className={classes.btn}
                  >
                    <IonIcon
                      icon={closeSharp}
                      color={'secondary'}
                      size="default"
                    />
                  </IonButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {_error.state && (
        <div className={classes.error}>
          <div className={classes.label + ' text-primary'}>Error!</div>
          <span className={classes.cont}>
            <div className={classes.msg}> {_error.msg}</div>
            <div className={classes.btn}>
              <IonButton
                fill={'clear'}
                onClick={() => {
                  setError(() => ({ state: false, msg: '' }));
                }}
                color="secondary"
              >
                <IonIcon slot="icon-only" icon={closeSharp}></IonIcon>
              </IonButton>
            </div>
          </span>
        </div>
      )}
    </div>
  );
};
