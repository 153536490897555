import { useRef, useState } from 'react';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import Nav from './nav/index';
import Footer from './footer/index';
import classes from './Layout.module.scss';
import { chevronUp } from 'ionicons/icons';

interface LayoutProps {
  children: React.ReactNode;
  enableScrollToToP?: boolean;
  enableRefresh?: boolean;
  handleRefresh?: (event: CustomEvent) => void;
}

const Layout = ({
  children,
  enableScrollToToP = false,
  enableRefresh = false,
  handleRefresh,
}: LayoutProps) => {
  const locations: any[] = [];
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const handleScroll = async (event: CustomEvent) => {
    const scrollTop = event.detail.scrollTop;
    // Show button after scrolling 200px
    setShowBackToTopButton(scrollTop > 200);
  };

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500);
  };


  return (
    <IonPage id="main-content">
      <IonContent
        ref={contentRef}
        onIonScroll={handleScroll}
        scrollEvents={true}
        className={classes.AppCarrier}
      >
        {/* Pull to Refresh */}
        {enableRefresh && handleRefresh && (
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}

        <div className={classes.nav + ' !bg-white'} id={'nav'}>
          <Nav />
        </div>
        <div className={classes.bodyContent}>
          <div className={classes.breadCrumbs + ' hidden'}>
            <IonBreadcrumbs>
              {locations.map((item: any, index: any) => {
                return (
                  <IonBreadcrumb color="primary" href={`/${item}`} key={index}>
                    {item}
                  </IonBreadcrumb>
                );
              })}
            </IonBreadcrumbs>
          </div>
          <div className={classes.children}>{children}</div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>

        {/* Scroll to top */}
        {enableScrollToToP && showBackToTopButton && (
          <IonFab slot="fixed">
            <IonFabButton size="small" onClick={scrollToTop}>
              <IonIcon icon={chevronUp} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Layout;
