import { IonModal, IonButton, IonIcon } from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import React, { ReactChild, ReactFragment, ReactPortal, useRef } from 'react';

export interface IgenericActionModal {
  title: string;
  open: boolean;
  trigger: string;
  onDismiss?: (_: any) => any;
  onPresent?: (_: any) => any;
  width?: string;
  height?: string;
  children:
    | ReactChild
    | ReactFragment
    | ReactPortal
    | boolean
    | null
    | undefined;
}

const GenericActionModal: React.FC<IgenericActionModal> = ({
  title,
  trigger,
  onDismiss,
  onPresent,
  width,
  open,
  height,
  children,
}) => {
  const modal = useRef<any>(null);
  return (
    <IonModal
      ref={modal}
      trigger={trigger}
      isOpen={open}
      onWillDismiss={onDismiss}
      onIonModalWillPresent={onPresent}
      style={{
        '--width': width,
        '--height': height || 'fit-content',
        '--min-width': width,
        '--max-width': 'unset',
        '--max-height': 'unset',
        '--border-radius': '10px',
        '--box-shadow': '0 28px 48px rgba(0, 0, 0, 0.4)',
      }}
    >
      <div className="flex flex-col items-center justify-center p-2">
        <div className="w-full min-h-[2rem] flex flex-col items-center">
          <div className="w-full h-[1.5em] flex items-center justify-end">
            <IonButton
              fill="clear"
              color="primary"
              onClick={() => {
                modal.current.dismiss();
              }}
              className="m-0 h-full"
              size="small"
            >
              <IonIcon
                icon={closeSharp}
                slot="icon-only"
                size="small"
              ></IonIcon>
            </IonButton>
          </div>
          <div className="w-full h-auto pb-2 text-[var(--ion-color-primary)] font-['Roboto', sans-serif] font-semibold text-xl flex items-center justify-center">
            {title}
          </div>
        </div>
        <div className="w-full h-auto flex flex-col items-center justify-center ">
          {children}
        </div>
      </div>
    </IonModal>
  );
};

export default GenericActionModal;
