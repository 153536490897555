import { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { getTotalProductLikes } from '../../utils/products';
import { getStore } from '../../context/actions/store';
import { GET_STORE_ERROR } from '../../context/actions/types';
import useGlobalContext from '../../context/provider';
import { ProductType } from '../../types/product';
import CurrentStoreCard from '../currentstorecard';
import { getCurrentStore } from '../../utils/currentStore';
import ErrorToast from '../error_toast';
import { storeSchema } from '../../types/store';
import { getCurrentUser } from '../../utils/getClientInfo';
import Spinner from '../spinner';

const StoreBar = () => {
  const history = useHistory();
  const currentStore = getCurrentStore();
  const [productLikes, setProductLikes] = useState<number>(0);
  const { storeState, storeDispatch } = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [store, setStore] = useState<storeSchema>({} as storeSchema);

  useEffect(() => {
    setLoading(true);
    fetchStore(currentStore!);
  }, []);

  useEffect(() => {
    if (store.id?.toString() === currentStore) {
      setLoading(false);
    }
  }, [store]);
  useEffect(() => {
    if (storeState?.store) {
      extractStore(storeState.store);
    
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState]);

  const fetchStore = async (_id: string) => {
    let store = storeState.store as storeSchema;
    if (store?.id?.toString() === _id) {
      extractStore(storeState.store);
      return;
    }
    await getStore(Number(_id))(storeDispatch);
  };

  const extractStore = (store: any) => {
    const { products, ...rest } = store;
    setProductLikes(getTotalProductLikes(products));
    setStore(store);
  };

  return (
    <div className={classes.container}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {loading ? (
        <div className="w-full h-[80dvh] flex items-center justify-center">
          <Spinner type="crescent" />
        </div>
      ) : (
        <IonGrid className={classes.storebar_grid}>
          {/* <IonRow className={classes.currentStore}>
          <IonCol size={'12'}>
            <CurrentStoreCard />
          </IonCol>
        </IonRow> */}
          <IonRow className={classes.store}>
            <IonCol size="12" className="!p-0">
              <div
                className={
                  classes.storeCard + ' bg-white !border-0 !rounded-3xl'
                }
              >
                <IonGrid className={classes.s_grid}>
                  <IonRow className={classes.head}>
                    <IonCol size="12">
                      <div className={classes.img}>
                        <img src={store.logo!} alt="Store Head"></img>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className={classes.title}>
                    <IonCol size="12">
                      <div className={classes.storeName}>{store.name}</div>
                    </IonCol>
                  </IonRow>
                  <IonRow className={classes.list}>
                    <IonCol size="12">
                      <div className={classes.listings}>
                        <div className={classes.label}>Total Products</div>
                        <div className={classes.tListings}>
                          {store.products?.length}
                        </div>
                      </div>
                      <div className={classes.views}>
                        <div className={classes.label}>Products Likes</div>
                        <div className={classes.tViews}>{productLikes}</div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol size="12">
              <div className={classes.recent}>
                <div className={classes.label}>
                  <div className={classes.text}>Recent Products</div>{' '}
                  <div
                    className={
                      classes.link + ' ion-activatable ripple-parent circle'
                    }
                    onClick={() => {
                      history.push({ pathname: '/dashboard/products' });
                    }}
                  >
                    <IonRippleEffect></IonRippleEffect>
                    See All
                  </div>
                </div>
                <div className={classes.list}>
                  {store.products?.slice(0, 4).map((item, index) => {
                    return (
                      <div
                        className={
                          ' ion-activatable ripple-parent circle bg-white !border-0 !rounded-2xl !px-4 !py-2 w-full h-auto flex items-center justify-start mb-2'
                        }
                        onClick={() => {
                          history.push({
                            pathname: `/dashboard/items/detail?q=${
                              item.id
                            }&i=${item.name.slice(0, 30)}`,
                          });
                        }}
                        key={index}
                      >
                        <IonRippleEffect></IonRippleEffect>
                        <div className="h-auto aspect-square w-[30%] mr-2 flex items-center justify-center rounded-full overflow-hidden border-2 border-gray-200">
                          <img
                            src={item?.images?.[0]}
                            alt={'Product'}
                            className="object-cover"
                          />
                        </div>
                        <div
                          className={
                            ' flex flex-col items-start justify-center'
                          }
                        >
                          <div className="text-primary text-base font-light ">
                            {item.name}
                          </div>{' '}
                          <div className="text-secondary text-sm font-regular ">
                            UGX{' '}
                            {item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default StoreBar;
