import { IonCard } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { format } from 'date-fns';
import { DeviceContext } from '../../../deviceType';
import { useHistory } from 'react-router';
import { getCurrentUser } from '../../../utils/getClientInfo';
import { ProductType } from '../../../types/product';
import { IoIosEye, IoIosHeart } from 'react-icons/io';

const DashProductCard = ({
  data,
  type,
}: {
  data: ProductType;
  type: 'views' | 'likes';
}) => {
  const size = useContext<any>(DeviceContext);
  const history = useHistory();
  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  const [_data, setData] = useState<ProductType>({
    createdAt: new Date().toISOString(),
  } as ProductType);

  useEffect(() => {
    setData(data);
  }, [data]);
  useEffect(() => {
    if (client.role === 'seller') {
      setPath(
        `/dashboard/items/detail?q=${_data.id}&i=${_data?.name?.slice(0, 30)}`
      );
    } else {
      setPath(`/items/detail?q=${_data.id}&i=${_data?.name?.slice(0, 30)}`);
    }
  }, [_data]);
  const pushroute = () => {
    history.push({
      pathname: path,
      state: { id: _data.id },
    });
  };
  return (
    <div
      className="size-full flex flex-col items-center justify-start overflow-hidden cursor-pointer"
      onClick={pushroute}
    >
      <div className="w-full h-[auto] aspect-square overflow-hidden rounded-xl">
        <img
          src={_data!?.images?.[0]}
          alt={'logo'}
          className="object-cover size-full"
        />
      </div>
      <div className="w-full h-auto px-2 pt-2 text-primary text-base font-semibold font-roboto text-wrap">
        {_data!?.name}
      </div>
      <div className="w-full px-2 flex flex-row items-center justify-between ">
        <div className="text-sm text-primary font-roboto font-semibold">
          UGX {_data!?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </div>
      </div>
      <div className="w-full px-2 flex flex-row items-center justify-start ">
        <div className="w-[25px] h-auto aspect-square text-secondary font-roboto font-semibold mr-2">
          {type === 'views' ? (
            <IoIosEye size="100%" />
          ) : (
            <IoIosHeart size="100%" />
          )}
        </div>
        <div className="text-base text-primary font-roboto font-semibold">
          {type === 'views' ? _data.views?.length : _data.bookmarks?.length}
        </div>
      </div>
    </div>
  );
};

export default DashProductCard;
