import { IonMenu } from '@ionic/react';
import { useContext, useRef } from 'react';
import SellerSlideMenu from './seller';
import UserSlideMenu from './user';
import classes from './styles.module.scss';
import { GlobalContext } from '../../context/provider';
import AdminSlideMenu from '../Layout/admin/nav/menu';
import { getCurrentUser } from '../../utils/getClientInfo';

const AppMenu = () => {
  const { userState } = useContext<any>(GlobalContext);
  const user = userState.getUser;
  const slideMenuRef = useRef<HTMLIonMenuElement>(null);
  const loggedInUser = getCurrentUser();

  return (
    <IonMenu
      side="start"
      swipe-gesture
      contentId="main-content"
      type="overlay"
      className={classes['slide-menu']}
      menuId="menu"
      ref={slideMenuRef}
    >
      {user && user.role === 'seller' && loggedInUser.stores?.length ? (
        <SellerSlideMenu user={user} action={slideMenuRef.current} />
      ) : user?.role === 'admin'? (
        <AdminSlideMenu action={slideMenuRef.current} />
      ) :(
        <UserSlideMenu user={user} action={slideMenuRef.current} />
      )}
    </IonMenu>
  );
};

export default AppMenu;
